.sec-howto{
	padding-bottom: 125px;
	background: url("../img/howto/mark1.png") no-repeat right top 860px, url("../img/howto/mark2.png") no-repeat left bottom;
	background-size: 329px auto;
	@include mq() {
		background: none;
		padding-bottom: 40px;
	}
	.ttl-comp01{
		margin-bottom: 60px;
		@include mq() {
			margin-bottom: 30px;
		}
	}
	.lead{
		text-align: center;
		@include font-size(22);
		line-height: 1.8;
		margin-bottom: 70px;
		@include mq() {
			@include font-size(12);
			margin-bottom: 30px;
		}
	}
	.howto-item{
		display:-webkit-box; // old
		display:-moz-box; // old
		display:-ms-flexbox; // ie
		display:-webkit-flex; // new
		display:flex; // new
		flex-wrap: wrap;
		margin-bottom: 85px;
		@include mq() {
			margin-bottom: 25px;
		}
		&__content{
			@include mq(md-min) {
				flex: 1;
			}
		}
		&__ttl{
			width: 180px;
			padding-right: 65px;
			@include mq() {
				width: 100%;
				padding: 30px 0 0;
				border-top: 1px solid #b5b5b5;
				margin-bottom: 25px;
			}
			strong{
				display: block;
				@include font-size(38);
				border-right: 1px solid #b5b5b5;
				padding: 10px 20px 0 0;
				margin-right: 20px;
				@include mq() {
					border: none;
					@include font-size(14);
					display: inline-block;
					padding: 0;
					margin-right: 15px;
				}
			}
			small{
				@include font-size(15);
				padding-top: 15px;
			}
		}
		&__img{
			margin-bottom: 53px;
			@include mq() {
				margin-bottom: 25px;
			}
		}
		&__list{
			li{
				display:-webkit-box; // old
				display:-moz-box; // old
				display:-ms-flexbox; // ie
				display:-webkit-flex; // new
				display:flex; // new
				flex-wrap: wrap;
				margin-bottom: 60px;
				@include mq() {
					margin-bottom: 20px;
				}
				&:last-of-type{
					margin-bottom: 0;
				}
				.num{
					width: 75px;
					@include mq() {
						width: 45px;
					}
				}
				.txt{
					padding-left: 55px;
					@include font-size(14);
					line-height: 1.9;
					@include mq(md-min) {
						flex: 1;
					}
					@include mq() {
						width: calc(100% - 45px);
						padding-left: 20px;
						@include font-size(11);
					}
				}
			}
			&--style02{
				display:-webkit-box; // old
				display:-moz-box; // old
				display:-ms-flexbox; // ie
				display:-webkit-flex; // new
				display:flex; // new
				flex-wrap: wrap;
				justify-content: space-between;
				li{
					width: 46%;
					@include mq(lg) {
						width: 100%;
					}
				}
			}
		}
	}
	.point-block{
		position: relative;
		border: 1px solid #c0c0c1;
		padding: 70px 45px 20px;
		display:-webkit-box; // old
		display:-moz-box; // old
		display:-ms-flexbox; // ie
		display:-webkit-flex; // new
		display:flex; // new
		flex-wrap: wrap;
		justify-content: space-between;
		@include mq() {
			padding: 40px 25px 5px;
			margin-top: 60px;
		}
		&__ttl{
			@include font-size(22);
			display: inline-block;
			padding: 0 25px;
			height: 50px;
			line-height: 50px;
			border-radius: 7px;
			background: #313131;
			color: #FFF;
			text-align: center;
			position: absolute;
			top: -25px;
			left: 45px;
			@include mq() {
				@include font-size(14);
				line-height: 35px;
				height: 35px;
				margin: auto;
				left: 0;
				right: 0;
				top: -18px;
				width: 200px;
				padding: 0;
			}
		}
		&__txt{
			width: 48%;
			@include font-size(16);
			line-height: 1.8;
			margin-bottom: 35px;
			@include mq() {
				width: 100%;
				@include font-size(11);
				margin-bottom: 20px;
			}
		}
	}
}