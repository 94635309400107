.sec-privacy {
	padding-bottom: 138px;
	@include mq {
		padding-bottom: 25px;
	}
}

.txt-privacy {
	@include font-size(14);
	line-height: 2.15;
	font-family: $font-gothic;
	padding: 34px 10px;
	@include mq {
		@include font-size(11);
		line-height: 1.71;
		padding: 0 4px;
	}
}