.sec-tokusho {
	padding-bottom: 137px;
	@include mq {
		padding-bottom: 5px;
	}
}

.list-tokusho {
	padding: 79px 10px;
	@include mq {
		padding: 0;
	}
	dl {
		border-bottom: 1px solid rgba(0,0,0,0.2);
		padding: 26px 0 20px;
		display: flex;
		flex-wrap: wrap;
		@include mq {
			padding: 0 0 10px;
			margin-bottom: 9px;
			&:last-child {
				border-bottom: none;
			}
		}
	}
	dt, dd {
		@include font-size(14);
		line-height: 1.71;
		@include mq {
			@include font-size(11);
			line-height: 1.82;
		}
	}
	dt {
		font-weight: bold;
		width: 191px;
		padding: 0 21px;
		@include mq {
			width: 100%;
			padding: 0;
		}
	}
	dd {
		width: calc(100% - 191px);
		@include mq {
			width: 100%;
		}
		a {
			color: inherit;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}