.gift-handle{
	&__img{
		position: relative;
		max-width: 820px;
		margin: 0 auto;
		.txt-basic{
			@include mq(md-min){
				position: absolute;
				top: 0;
				right: 0;
				
				max-width: 325px;
				text-align: left;
				margin: 53px 0 0;
				padding-right: 18px;
			}
			@include mq{
				text-align: center;
				margin-bottom: 29px;
			}
		}
		img{
			margin-bottom: 27px;
		}
	}
}
.list-step{
	&--style02{
		.list-step__txt{
			display: flex;
			justify-content: flex-end;
			width: 100%;
			padding-right: 43px;
			border-bottom: solid 1px #b0b0b0;
			padding-bottom: 32px;
			margin-bottom: 32px;
			@include mq{
				padding-right: 25px;
				padding-bottom: 10px;
				margin-bottom: 15px;
				justify-content: center;
			}
			@include mq(xs){
				justify-content: flex-end;
			}
		}
		.list-step__ttl{
			margin-left: 55px;
			text-align: left;
			//max-width: 70px;
			margin-bottom: 0;
			width: 70px;
			@include mq{
				@include font-size(20);
				margin-left: 0;
			}
		}
		.txt-basic{
			height: 180px;
			line-height: 1.9;
			padding-bottom: 0;
			border-bottom: 0;
			margin-bottom: 0;
			text-align: left;
			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				width: calc(100% - 70px);
			}
			@include mq{
				height: 135px;
			}
			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and(max-width: 834px) {
				width: 170px
			}
		}
	}
}
.sec-gift{
	&--style02{
		.gift-story{
			padding-bottom: 70px;
			@include mq{
				padding-top: 28px;
				padding-bottom: 25px;
			}
			.breadcrumb{
				margin-bottom: 45px;
			}
		}
		.gift-handle{
			padding-top: 62px;
			padding-bottom: 0;
			border-bottom: solid 1px #c3c1c4;
			margin-bottom: 54px;
			@include mq{
				padding-top: 26px;
				margin-bottom: 28px;
				img{
					margin-bottom: 33px;
				}
			}
		}
		.ttl-com06{
			margin-bottom: 30px;
		}
	}
}
.sec-story {
	&--style02{
		.ttl-com06{
			margin-bottom: 55px;
			@include mq{
				margin-bottom: 30px;
			}
		}
	}
}
.mt0{
	margin-top: 0;
}
.sec-menu{
	&--style02{
		.ttl-com06{
			margin-bottom: 55px;
			@include mq{
				margin-bottom: 30px;
			}
		}
	}
}
.ip6{
	display: none;
	@media(max-width: 375px){
		display: initial;
	}
	@include mq(sm-ip){
		display: none;
	}
}