.ouchi-intro{
	margin-bottom: 65px;
	@include mq {
		margin-bottom: 30px;
	}
	.intro-block{
		padding: 65px 0 20px;
		border-top: 1px solid #bdc3c2;
		border-bottom: 1px solid #bdc3c2;
		@include mq {
			padding: 30px 0 0;
		} 
	}
	.intro-item{
		position: relative;
		padding-left: 265px;
		padding-top: 20px;
		margin-bottom: 65px;
		@include mq {
			padding: 0;
			max-width: 375px;
			margin: 0 auto 30px;
		}
		&__ttl{
			position: absolute;
			left: -25px;
			top: 0;
			width: 265px;
			@include mq {
				position: static;
				left: inherit;
				width: 100px;
				min-height: 300px;
			}
			.ttl{
				&--01{
					width: 312px;
					@include mq {
						width: 100%;
					}
				}
				&--02{
					margin: 20px auto 0;
					padding-left: 40px;
					@include font-size(34);
					@include mq {
						@include font-size(15);
						padding: 0;
						margin-top: 12px;
					}
				}
			}
		}
		&__img{
			display:-webkit-box; // old
			display:-moz-box; // old
			display:-ms-flexbox; // ie
			display:-webkit-flex; // new
			display:flex; // new
			flex-wrap: wrap;
			justify-content: space-between;
			margin-bottom: 43px;
			@include mq {
				margin-bottom: -25px;
			}
			.img{
				width: calc( calc(100% - 5px) / 2);
				@include mq {
					width: calc( calc(100% - 3px) / 2);
				}
				&:nth-of-type(1){
					width: 100%;
					margin-bottom: 5px;
					@include mq {
						margin-bottom: 3px;
					}
				}
				img {
					width: 100%;
					height: 282px;
					object-fit: cover;
					font-family: "object-fit:cover;";
					@include mq {
						height: 107px;
					}
				}
			}
			&--style02{
				.left-col,
				.right-col{
					width: calc( calc(100% - 5px) / 2);
					@include mq {
						width: calc( calc(100% - 3px) / 2);
					}
				}
				.left-col{
					margin-right: 5px;
					@include mq {
						margin-right: 3px;
					}
					.img{
						&:nth-of-type(1){
							img{
								height: 570px;
								@include mq {
									height: 217px;
								}
							}
						}
					}
				}
				.right-col{
					.img{
						width: 100%;
						margin-right: 0;
					}
				}
			}
		}
		&__content{
			@include mq(md-min) {
				position: relative;
				padding-left: 45%;
			}
			.img{
				position: absolute;
				left: 0;
				width: 48%;
				z-index: 2;
				@include mq{
					left: inherit;
					width: 65%;
					max-width: 220px;
				}
			}
			.txt-inner{
				width: 100%;
				position: relative;
				border-left: 1px solid #888687 ;
				display:-webkit-box; // old
				display:-moz-box; // old
				display:-ms-flexbox; // ie
				display:-webkit-flex; // new
				display:flex; // new
				flex-wrap: wrap;
				justify-content: space-between;
				min-height: 300px;
				padding-bottom: 22px;
				@include mq {
					border: none;
					min-height: inherit;
					padding: 0;
					margin-bottom: 20px;
					margin-top: 20px;
				}
				.ttl-col{
					width: 125px;
					@include mq {
						order: 2;
						width: 50px;
					}
				}
				.txt-col{
					width: calc(100% - 125px);
					@include mq {
						order: 1;
						width: calc(100% - 50px);
						padding-right: 20px;
						border-right: 1px solid #888687 ;
						padding-bottom: 10px;
					}
				}
				.ttl{
					@include font-size(52);
					margin: 0 auto;
					padding-top: 5px;
					@include mq {
						@include font-size(24);
					}
				}
				.txt{
					@include font-size(16);
					line-height: 2.17;
					margin-bottom: 40px;
					@include mq {
						margin-bottom: 0;
						@include font-size(12);
					}
				}
			}
			.btn{
				width: 100%;
				border-radius: 4px;
				@include mq {
					border-radius: 0;
					height: 45px;
				}
			}
		}
		&--01{
			.intro-item__content{
				.img{
					top: -85px;
					@include mq{
						right: 5px;
						top: 235px;
						max-width: 200px;
					}
				}
			}
		}
		&--02{
			padding-left: 0;
			padding-right: 265px;
			@include mq {
				padding-right: 0;
			}
			.intro-item__ttl{
				left: inherit;
				right: 0;
				.ttl--01,
				.ttl--02{
					position: relative;
					left: -25px;
					z-index: 2;
					@include mq {
						position: static;
						left: inherit;
					}
				}
			}
			.intro-item__content{
				.img{
					top: 35px;
					@include mq {						
						right: -20px;
						top: 260px;
					}
				}
			}
		}
		&--03{
			.intro-item__content{
				.img{
					top: 5px;
					@include mq {
						right: -20px;
						top: 250px;
					}
				}
			}
		}
	}
}