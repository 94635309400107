.sec {
	&-main-virual {
		position: relative;
		margin-bottom: 94px;
		@include mq {
			margin-bottom: 36px;
		}
	}
	&-banner {
		margin-bottom: 111px;
		@include mq {
			margin-bottom: 2px;
		}
	}
	&-bnr {
		margin-bottom: 82px;
		text-align: center;
		@include mq {
			margin-bottom: 36px;
		}
	}
	&-message {
		margin-bottom: 73px;
		@include mq {
			margin-bottom: 28px;
		}
	}
	&-line-up {
		margin-bottom: 76px;
		@include mq {
			margin-bottom: 17px;
			.inner {
				padding-right: 0;
			}
		}
	}
	&-purchase {
		background-color: rgba(0,0,0,0.1);
		padding: 64px 0 67px;
		@include mq {
			padding: 23px 0 17px;
		}
	}
	&-food {
		position: relative;
		.inner {
			position: absolute;
			top: 49px;
			bottom: 40px;
			left: 0;
			right: 0;
			z-index: 5;
			@include mq {
				top: 19px;
				bottom: 19px;
			}
		}
	}
	&-gift-rapping {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			z-index: -1;
			bottom: -130px;
			left: -53px;
			background: url("../img/index/ico-mark.png") no-repeat center / contain;
			width: 399px;
			height: 400px;
			@include mq {
				display: none;
			}
		}
	}
}

.slider-home{
	.item{
		outline: none !important;
		position: relative;
		&__img img {
			width: 100%;
			height: 800px;
			object-fit: cover;
			font-family: "object-fit:cover;";
			@include mq {
				height: 400px;
			}
		}
		&__ttl {
			@include font-size(36);
			line-height: 1.5;
			color: #ffffff;
			letter-spacing: 0.2em;
			@include text-vertical;
			margin-right: 59px;
			@include mq {
				margin-right: 0;
				@include font-size(18);
				line-height: 1.47;
			}
		}
		.inner {
			position: absolute;
			z-index: 2;
			top: 130px;
			left: 0;
			right: 0;
			/*bottom: 255px;*/
			display: flex;
			justify-content: flex-end;
			@include mq {
				justify-content: center;
				top: 63px;
				right: 10px;
				bottom: 137px;
			}
		}
	}
	.slick-dots {
		bottom: -51px;
		@include mq {
			bottom: -23px;
		}
		li {
			margin: 0 5px;
			&.slick-active button {
				background-color: $color-red;
			}
			button {
				padding: 0;
				width: 100px;
				height: 2px;
				background-color: rgba(0,0,0,0.2);
				@include mq {
					width: 70px;
				}
				&:before {
					display: none;
				}
			}
		}
	}
}

.main-virual {
	&__block {
		position: absolute;
		bottom: 49px;
		left: 0;
		right: 0;
		z-index: 10;
		@include mq {
			bottom: 17px;
		}
		.inner {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			@include mq {
				align-items: center;
			}
		}
	}
	&__img {
		width: 268px;
		margin-bottom: 31px;
		position: relative;
		right: -10px;
		@include mq {
			width: 137px;
			right: auto;
			margin-bottom: 19px;
		}
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			height: 115px;
		}
	}
}

.slider-banner {
	@include mq {
		display: block;
	}
	.item {
		padding: 0 32px;
		max-width: 820px;
		@include mq {
			padding: 0 20px 15px;
		}
		img {
			width: 100%;			
		}
	}
	.slick-dots {
		bottom: -47px;
		li {
			margin: 0 5px;
			&.slick-active button {
				background-color: $color-red;
			}
			button {
				padding: 0;
				width: 100px;
				height: 2px;
				background-color: rgba(0,0,0,0.2);
				&:before {
					display: none;
				}
			}
		}
	}
}

.message {
	&__row {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		border-bottom: 1px solid rgba(0,0,0,0.2);
		padding-bottom: 75px;
		position: relative;
		@include mq {
			padding-bottom: 30px;
			max-width: 280px;
			margin: 0 auto;
		}
		&:after {
			content: '';
			position: absolute;
			z-index: -1;
			right: -235px;
			bottom: -125px;
			background: url("../img/index/ico-mark.png") no-repeat center / contain;
			width: 321px;
			height: 321px;
			@include mq {
				width: 70px;
				height: 70px;
				bottom: auto;
				right: -13px;
				top: 52%;
				transform: translateY(-50%);
			}
		}
	}
	&__left {
		width: 20.5%;
		@include mq {
			width: 100%;
			order: 1;
			margin-top: 18px;
			display: flex;
		}
	}
	&__img {
		margin: 0 0 35px 2px;
		max-width: 200px;
		@include mq {
			margin: 0;
			padding-left: 9px;
			max-width: none;
			width: 126px;
			flex-shrink: 0;
		}
	}
	&__wrap {
		display: flex;
		margin-left: 6px;
		@include mq {
			margin-left: 0;
			align-items: flex-end;
			width: calc(100% - 126px);
			padding-left: 6px;
		}
	}
	&__stamp {
		width: 42px;
		flex-shrink: 0;
		@include mq {
			width: 27px;
		}
	}
	&__content {
		display: flex;
		width: calc(100% - 42px);
		height: 152px;
		@include mq {
			height: 117px;
			margin-left: 0;
			width: calc(100% - 27px);
		}
	}
	&__txt {
		display: flex;
		justify-content: flex-end;
		@include font-size(14);
		line-height: 1.72;
		width: calc(100% - 35px);
		@include mq(lg) {
			@include font-size(12);
			width: calc(100% - 32px);
		}
		@include mq {
			@include font-size(11);
			line-height: 1.9;
		}
	}
	&__heading {
		@include font-size(18);
		letter-spacing: 0.1em;
		padding-left: 10px;
		width: 35px;
		@include mq(lg) {
			@include font-size(16);
			padding-left: 3px;
			width: 32px;
		}
		@include mq {
			font-weight: 400;
			@include font-size(12);
			margin: 0;
			padding: 0 8px;
			flex-shrink: 0;
		}
	}
	&__center {
		width: 47.5%;
		@include font-size(19.5);
		line-height: 2.06;
		letter-spacing: 0.14em;
		height: 400px;
		display: flex;
		justify-content: flex-end;
		@include mq(lg) {
			width: 52.5%;
			@include font-size(19);
		}
		@include mq {
			width: 60%;
			@include font-size(11);
			line-height: 1.9;
			height: 260px;
			letter-spacing: 0.04em;
			padding-top: 3px;
		}
	}
	&__right {
		width: 32%;
		display: flex;
		margin-top: -5px;
		@include mq(lg) {
			width: 27%;
		}
		@include mq {
			width: 40%;
			margin-top: 0;
			align-items: flex-start;
		}
	}
	&__ttl {
		@include font-size(40.08);
		line-height: 1.53;
		letter-spacing: 0.05em;
		width: calc(100% - 60px);
		height: 346px;
		padding-right: 30px;
		padding-top: 5px;
		display: flex;
		justify-content: flex-end;
		@include mq(lg) {
			@include font-size(26);
			width: calc(100% - 50px);
			padding-right: 15px;
		}
		@include mq {
			@include font-size(17);
			line-height: 1.48;
			width: calc(100% - 32px);
			padding-right: 16px;
			padding: 9px 16px 0 0;
			height: 260px;
		}
	}
	&__greeting {
		border-left: 1px solid rgba(0,0,0,0.2);
		@include font-size(24);
		letter-spacing: 0.1em;
		/*padding-top: 8px;*/
		margin-top: 5px;
		width: 60px;
		display: flex;
		justify-content: flex-end;
		@include mq(lg) {
			width: 50px;
		}
		@include mq {
			@include font-size(11);
			width: 32px;
			padding: 5px 7px 0 5px;
			height: 210px;
		}
		.en {
			@include font-size(15);
			padding-top: 28px;
			@include mq {
				@include font-size(10);
				padding-top: 8px;
			}
		}
	}
}

.line-up {
	&__ttl {
		@include font-size(25);
		line-height: 1.6412;
		margin-bottom: 37px;
		@include mq {
			@include font-size(14);
			line-height: 1.43;
			margin-bottom: 13px;
		}
		.en {
			@include font-size(14);
			letter-spacing: 0.1em;
			margin-left: 3px;
			@include mq {
				@include font-size(10);
				letter-spacing: 0.05em;
				margin-left: 10px;
			}
		}
	}
	&__list {
		/*display: flex;
		margin: 0 -27px;*/
		position: static;
		@include mq {
			display: block;
			margin: 0;
		}
		.slick-list{
			overflow: inherit;
		}
		.slick-disabled{
			opacity: 0.5;
			cursor: default;
		}
		.item {
			/*width: 33.33333%;
			padding: 0 27px;*/
			width: 280px;
			margin-right: 60px;
			@include mq(lg) {
				width: 230px;
				margin-right: 30px;
			}
			@include mq {
				width: 194px;
				padding: 0 9px;
				margin-right: 14px;
			}
			&:focus{
				outline: none;
			}
			&__img {
				text-align: center;
				margin-bottom: 18px;
				@include mq {
					margin-bottom: 11px;
				}
				&:focus{
					outline: none;
				}
			}
			&__ttl {
				display: block;
				@include font-size(20);
				line-height: 1.5;
				margin-bottom: 12px;
				letter-spacing: 2px;
				color: $color-text-black;
				&:hover {
					text-decoration: underline;
				}
				&:focus{
					outline: none;
				}
				@include mq {
					@include font-size(12);
					padding-left: 5px;
					margin-bottom: 8px;
				}
			}
			&__txt {
				@include font-size(16);
				line-height: 1.5;
				letter-spacing: 0.1em;
				color: $color-text-black;
				@include mq {
					@include font-size(11);
					line-height: 1.71;
					padding-left: 3px;
				}
				&:focus{
					outline: none;
				}
			}
		}
		.slick-arrow{
			position: absolute;
			width: 40px;
			height: 40px;
			top: 50%;
			transform: translateY(-50%);
			font-size: 0;
			z-index: 50;
			@include mq {
				width: 26px;
				height: 26px;
			}
			&:before{
				display: none;
			}
			&.slick-prev{
				left: 0;
				background: url("../img/product/arrow-prev.png") no-repeat;
				background-size: 100% auto;
			}
			&.slick-next{
				right: 0;
				background: url("../img/product/arrow-next.png") no-repeat;
				background-size: 100% auto;
			}
		}
	}
}

.purchase {
	&__top {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		border-bottom: 1px solid rgba(0,0,0,0.2);
		padding-bottom: 40px;
		margin-bottom: 60px;
		@include mq {
			border-bottom: none;
			padding-bottom: 0;
			margin-bottom: 11px;
		}
		.btn {
			margin-bottom: 31px;
			@include mq {
				margin-bottom: 9px;
			}
		}
	}
	&__txt {
		@include font-size(15);
		letter-spacing: 0.05em;
		@include mq {
			@include font-size(11);
			line-height: 1.9;
		}
	}
	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 0 39px;
		@include mq {
			margin: 0 0 3px;
		}
		> li {
			width: 50%;
			padding: 0 20px;
			@include mq {
				padding: 0 10px;
			}
			@include mq(sm) {
				width: 100%;
				padding: 0;
				margin-bottom: 7px;
				&:first-child {
					order: 1;
				}
			}
		}
	}
	&__links {
		display: flex;
		justify-content: flex-end;
		padding: 0 23px;
		@include mq {
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
		}
		> li {
			margin-left: 50px;
			@include mq {
				margin-left: 0;
				margin-bottom: 2px;
			}
			&:hover > a {
				text-decoration: none;
			}
			> a {
				@include font-size(16);
				letter-spacing: 0.05em;
				text-decoration: underline;
				color: $color-text-black;
				@include mq {
					@include font-size(11);
					line-height: 1.9;
				}
			}
		}
	}
}

.brand-concept {
	&__list {
		@include mq {
			margin: 27px auto 14px;
			max-width: 280px;
		}
		.item {
			display: flex;
			position: relative;
			margin-bottom: 3px;
			@include mq {
				margin-bottom: 18px;
				flex-wrap: wrap;
				&:last-child {
					margin-bottom: 0;
				}
			}
			&__img {
				width: calc(49.5% + 150px);
				margin-left: -150px;
				@media screen and (min-width : 835px) and (max-width : 1320px){
					margin-left: 0;
				}
				@include mq {
					margin: 0 -20px 25px;
					width: calc(100% + 40px);
				}
				img {
					width: 100%;
					height: 500px;
					object-fit: cover;
					font-family: "object-fit:cover;";
					@include mq {
						height: 248px;
					}
				}
				&--style01,
				&--style02,
				&--style03{
					display:-webkit-box; // old
					display:-moz-box; // old
					display:-ms-flexbox; // ie
					display:-webkit-flex; // new
					display:flex; // new
					flex-wrap: wrap;
					.img{
						margin: 0 0 5px;
						height: 247px;
						overflow: hidden;
						@include mq {
							height: 123px;
							margin-bottom: 3px;
						}
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							font-family: "object-fit:cover;";
						}
					}
				}
				&--style01{
					.item__img--first{
						width: 100%;
					}
					.item__img--last{
						width: 100%;
						display:-webkit-box; // old
						display:-moz-box; // old
						display:-ms-flexbox; // ie
						display:-webkit-flex; // new
						display:flex; // new
						flex-wrap: wrap;
						justify-content: space-between;
						.img{
							width: calc( calc(100% - 5px) / 2 );
							@include mq {
								width: calc( calc(100% - 3px) / 2 );
							}
						}
					}
				}
				&--style02,
				&--style03{
					justify-content: space-between;
					.item__img--first,
					.item__img--last{
						width: calc( calc(100% - 5px) / 2 );
						@include mq {
							width: calc( calc(100% - 3px) / 2 );
						}
					}
					
					.item__img--first{
						.img{
							height: 499px;
							@include mq {
								height: 249px;
							}
						}
					}
				}
				&--style03{
					.item__img--first{
						order: 2;
					}
					.item__img--last{
						order: 1;
					}
				}
			}
			&__content {
				width: 50.5%;
				display: flex;
				align-items: flex-start;
				padding: 53px 5px 0 30px;
				@include mq {
					width: 100%;
					padding: 0;
				}
			}
			&__txt {
				display: flex;
				justify-content: flex-end;
				@include font-size(16);
				line-height: 2.17;
				padding-top: 6px;
				height: 366px;
				width: calc(100% - 172px);
				@include mq(lg) {
					@include font-size(14);
					width: calc(100% - 146px);
				}
				@include mq {
					@include font-size(11);
					line-height: 1.9;
					height: 220px;
					margin-left: 0;
					justify-content: flex-end;
					width: calc(100% - 99px);
				}
			}
			&__ttl {
				letter-spacing: 0.05em;
				@include font-size(26.6);
				line-height: 1.6;
				padding-top: 6px;
				height: 410px;
				padding-left: 42px;
				width: 126px;
				@include mq(lg) {
					padding-left: 15px;
					@include font-size(24);
					width: 99px;
				}
				@include mq {
					@include font-size(15);
					line-height: 2;
					padding-top: 3px;
					padding-left: 10px;
					height: 230px;
					width: 70px;
				}
			}
			&__lb {
				@include font-size(14);
				letter-spacing: 0.05em;
				width: 32px;
				height: 202px;
				border-left: 1px solid rgba(0,0,0,0.2);
				padding-top: 6px;
				margin-left: 26px;
				flex-shrink: 0;
				display: flex;
				justify-content: flex-end;
				@include mq(lg) {
					margin-left: 15px;
				}
				@include mq {
					@include font-size(11);
					width: 29px;
					padding: 4px 3px;
					margin-left: 14px;
				}
			}
			&--first {
				@include mq(md-min) {
					margin-bottom: -53px;
					.item__img {
						margin-top: -87px;
						margin-left: -408px;
						width: calc(49.5% + 550px);
						position: relative;
						left: -57px;
						@include mq(lg) {
							left: 0;
						}
						img {
							height: 639px;
							object-position: right;
						}
					}
				}
				@include mq {
					margin-bottom: 16px;
					.item__img {
						margin: 0 0 -11px -110px;
						width: calc(100% + 96px);
						img {
							height: 254px;
							object-position: right;
						}
					}
				}
			}
			&:nth-child(even) {
				@include mq(md-min) {
					flex-direction: row-reverse;
					.item__img {
						margin-left: 0;
						margin-right: -150px;
					}
					.item__content {
						padding: 62px 83px 0 0px;
						position: relative;
						left: -9px;
					}
				}
				@media screen and (min-width : 835px) and (max-width : 1320px){
					.item__img {
						margin-right: -0px;
					}
				}
			}
		}
	}
}

.food {
	&__img img {
		height: 1200px;
		width: 100%;
		object-fit: cover;
		font-family: "object-fit:cover;";
		@include mq {
			height: 250px;
		}
	}
	&__header {
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
	}
	&__ttl {
		@include text-vertical;
		@include font-size(38.83);
		line-height: 1.55;
		color: #ffffff;
		padding-top: 12px;
		height: 572px;
		@include mq {
			@include font-size(15);
			line-height: 2;
			height: 207px;
			padding-top: 4px;
			letter-spacing: 0.04em
		}
	}
	&__lb {
		@include text-vertical;
		@include font-size(20.46);
		color: #ffffff;
		border-left: 1px solid #b5b5b5;
		margin: 0 65px 0 19px;
		padding: 12px 0 102px 20px;
		@include mq {
			@include font-size(11);
			margin: 0 3px 0 14px;
			padding: 4px 0 14px 12px;
		}
		small {
			@include font-size(15);
			letter-spacing: 0.1em;
			margin-top: 16px;
			@include mq {
				@include font-size(10);
				margin-top: 8px;
				font-weight: bold;
				letter-spacing: 0;
			}
		}
	}
	&__txt {
		@include font-size(16);
		line-height: 2.19;
		color: #ffffff;
		letter-spacing: 0.04em;
		max-width: 397px;
		display: flex;
		margin-left: auto;
		margin-right: 80px;
		@include mq {
			display: none;
		}
	}
}

.gift-rapping {
	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 106px -53px 43px;
		@include mq(lg) {
			margin: 106px -20px 43px;
		}
		@include mq {
			margin: 30px -10px 1px;
		}
		.item {
			width: 50%;
			padding: 0 53px;
			margin-bottom: 52px;
			@include mq(lg) {
				padding: 0 20px;
			}
			@include mq {
				padding: 0 10px;
				margin-bottom: 26px;
			}
			&__img {
				margin-bottom: 32px;
				@include mq {
					margin-bottom: 19px;
				}
				img {
					width: 100%;
				}
			}
			&__ttl {
				display: block;
				@include font-size(23);
				line-height: 1.875;
				letter-spacing: 0.1em;
				margin-bottom: 10px;
				@include mq {
					@include font-size(14);
					line-height: 1.43;
				}
			}
			&__txt {
				display: block;
				@include font-size(16);
				line-height: 1.875;
				letter-spacing: 0.1em;
				@include mq {
					@include font-size(11);
					line-height: 1.43;
				}
			}
		}
	}
}

.order {
	&__list {
		margin: 100px 0 90px;
		@include mq {
			margin: 30px 0 33px;
		}
		.item {
			margin-bottom: 53px;
			@include mq {
				margin-bottom: 27px;
			}
			&__banner {
				margin-bottom: 56px;
				@include mq {
					margin-bottom: 30px;
				}
				img {
					width: 100%;
				}
			}
			&__wrap {
				display: flex;
				flex-wrap: wrap;
				margin: 0 -48px 0 -28px;
				@include mq(lg) {
					margin: 0 -20px;
				}
				@include mq {
					margin: 0;
				}
			}
			&__left {
				width: 47%;
				padding: 24px 48px 0;
				@include mq(lg) {
					padding: 24px 20px 0;
				}
				@include mq {
					width: 100%;
					padding: 0;
				}
			}
			&__right {
				width: 53%;
				padding: 0 48px;
				@include mq(lg) {
					padding: 0 20px;
				}
				@include mq {
					width: 100%;
					padding: 0;
				}
			}
			&__img {
				margin-bottom: 10px;
				@include mq {
					margin: 0 10px 20px 20px;
				}
			}
			&__caption {
				text-align: left;
				@include font-size(16);
				line-height: 2.17;
				letter-spacing: 0.1em;
				padding-right: 5px;
				@include mq {
					display: none;
				}
			}
			&__ttl {
				@include font-size(28);
				line-height: 1.79;
				margin-bottom: 10px;
				@include mq(lg) {
					@include font-size(20);
				}
				@include mq {
					@include font-size(15);
					line-height: 1.67;
				}
			}
			&__txt {
				@include font-size(16);
				line-height: 1.875;
				padding-bottom: 22px;
				border-bottom: 1px solid rgba(0,0,0,0.2);
				margin-bottom: 45px;
				@include mq {
					@include font-size(11);
					letter-spacing: 0.05em;
					line-height: 1.43;
					padding-bottom: 20px;
					margin-bottom: 20px;
				}
			}
			&__box {
				display: flex;
				justify-content: flex-end;
				align-items: flex-start;
				margin-bottom: 23px;
				@include mq {
					justify-content: space-between;
					margin-bottom: 19px;
				}
			}
			&__input {
				display: flex;
				align-items: center;
				margin-right: 26px;
				span {
					@include font-size(14);
					line-height: 1.43;
					letter-spacing: 0.05em;
					margin-right: 10px;
					padding-top: 5px;
					@include mq {
						@include font-size(11);
						padding-top: 8px;
					}
				}
				input {
					width: 80px;
					height: 40px;
					line-height: 37;
					border: 1px solid rgba(0,0,0,0.2);
					text-align: right;
					padding: 0 15px;
					@include font-size(14);
					line-height: 1.43;
					font-family: $font-gothic;
					@include mq {
						@include font-size(11);
					}
				}
			}
			&__payment {
				flex-shrink: 0;
			}
			&__price {
				color: $color-text-red;
				@include font-size(30);
				line-height: 1.3;
				margin-bottom: 2px;
				@include mq {
					@include font-size(20);
					margin-bottom: 0;
				}
				small {
					display: inline-block;
					letter-spacing: 0.1em;
					@include font-size(16);
					@include mq {
						@include font-size(11);
					}
				}
			}
			&__pt {
				@include font-size(14);
				line-height: 1.43;
				letter-spacing: 0.05em;
				text-align: right;
				@include mq {
					@include font-size(11);
				}
				strong {
					display: inline-block;
					margin-left: 5px;
					color: $color-text-red;
					@include font-size(16);
					line-height: 1.25;
					font-weight: normal;
					@include mq {
						@include font-size(12);
					}
				}
			}
			&__btn {
				margin-bottom: 28px;
				@include mq {
					margin-bottom: 10px;
				}
				.btn {
					width: 100%;
					@include font-size(20);
					@include mq {
						height: 47px;
						@include font-size(14);
						span:after {
							width: 8px;
							height: 9px;
							top: 48%;
						}
					}
					@include mq(md-min) {
						span {
							padding-right: 0;
							&:after {
								display: none;
							}
						}
					}
				}
			}
			&__more {
				@include font-size(14);
				line-height: 1.79;
				letter-spacing: 0.05em;
				text-align: right;
				@include mq {
					letter-spacing: 0;
					@include font-size(11);
					line-height: 1.43;
				}
				strong {
					display: block;
					@include font-size(15);
					line-height: 1.33;
					margin-bottom: 7px;
					@include mq {
						@include font-size(11);
						line-height: 1.43;
						margin-bottom: 4px;
					}
				}
			}
		}
	}
}