@charset "UTF-8";
/* ====================================================
reset style
==================================================== */
html {
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 10px;
  /* Base font size, change to 14px or 16px if needed */
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

address, caption, cite, code, dfn, var {
  font-style: normal;
  font-weight: normal;
}

li {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: '';
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

/*to enable resizing for IE*/
input, textarea, select {
  *font-size: 100%;
}

/*because legend doesn't inherit in IE */
legend {
  color: #000;
}

del, ins {
  text-decoration: none;
}

main {
  display: block;
}

section {
  position: relative;
}

/* ====================================================
Font
==================================================== */
/* ====================================================
Media Quary
==================================================== */
@media screen and (min-width: 835px) {
  .mbpc-1 {
    margin-bottom: 1px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-1 {
    margin-bottom: 1px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-2 {
    margin-bottom: 2px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-2 {
    margin-bottom: 2px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-3 {
    margin-bottom: 3px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-3 {
    margin-bottom: 3px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-4 {
    margin-bottom: 4px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-4 {
    margin-bottom: 4px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-5 {
    margin-bottom: 5px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-5 {
    margin-bottom: 5px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-6 {
    margin-bottom: 6px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-6 {
    margin-bottom: 6px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-7 {
    margin-bottom: 7px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-7 {
    margin-bottom: 7px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-8 {
    margin-bottom: 8px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-8 {
    margin-bottom: 8px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-9 {
    margin-bottom: 9px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-9 {
    margin-bottom: 9px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-10 {
    margin-bottom: 10px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-10 {
    margin-bottom: 10px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-11 {
    margin-bottom: 11px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-11 {
    margin-bottom: 11px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-12 {
    margin-bottom: 12px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-12 {
    margin-bottom: 12px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-13 {
    margin-bottom: 13px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-13 {
    margin-bottom: 13px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-14 {
    margin-bottom: 14px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-14 {
    margin-bottom: 14px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-15 {
    margin-bottom: 15px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-15 {
    margin-bottom: 15px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-16 {
    margin-bottom: 16px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-16 {
    margin-bottom: 16px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-17 {
    margin-bottom: 17px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-17 {
    margin-bottom: 17px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-18 {
    margin-bottom: 18px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-18 {
    margin-bottom: 18px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-19 {
    margin-bottom: 19px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-19 {
    margin-bottom: 19px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-20 {
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-20 {
    margin-bottom: 20px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-21 {
    margin-bottom: 21px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-21 {
    margin-bottom: 21px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-22 {
    margin-bottom: 22px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-22 {
    margin-bottom: 22px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-23 {
    margin-bottom: 23px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-23 {
    margin-bottom: 23px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-24 {
    margin-bottom: 24px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-24 {
    margin-bottom: 24px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-25 {
    margin-bottom: 25px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-25 {
    margin-bottom: 25px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-26 {
    margin-bottom: 26px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-26 {
    margin-bottom: 26px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-27 {
    margin-bottom: 27px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-27 {
    margin-bottom: 27px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-28 {
    margin-bottom: 28px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-28 {
    margin-bottom: 28px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-29 {
    margin-bottom: 29px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-29 {
    margin-bottom: 29px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-30 {
    margin-bottom: 30px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-30 {
    margin-bottom: 30px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-31 {
    margin-bottom: 31px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-31 {
    margin-bottom: 31px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-32 {
    margin-bottom: 32px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-32 {
    margin-bottom: 32px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-33 {
    margin-bottom: 33px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-33 {
    margin-bottom: 33px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-34 {
    margin-bottom: 34px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-34 {
    margin-bottom: 34px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-35 {
    margin-bottom: 35px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-35 {
    margin-bottom: 35px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-36 {
    margin-bottom: 36px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-36 {
    margin-bottom: 36px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-37 {
    margin-bottom: 37px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-37 {
    margin-bottom: 37px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-38 {
    margin-bottom: 38px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-38 {
    margin-bottom: 38px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-39 {
    margin-bottom: 39px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-39 {
    margin-bottom: 39px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-40 {
    margin-bottom: 40px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-40 {
    margin-bottom: 40px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-41 {
    margin-bottom: 41px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-41 {
    margin-bottom: 41px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-42 {
    margin-bottom: 42px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-42 {
    margin-bottom: 42px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-43 {
    margin-bottom: 43px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-43 {
    margin-bottom: 43px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-44 {
    margin-bottom: 44px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-44 {
    margin-bottom: 44px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-45 {
    margin-bottom: 45px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-45 {
    margin-bottom: 45px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-46 {
    margin-bottom: 46px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-46 {
    margin-bottom: 46px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-47 {
    margin-bottom: 47px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-47 {
    margin-bottom: 47px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-48 {
    margin-bottom: 48px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-48 {
    margin-bottom: 48px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-49 {
    margin-bottom: 49px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-49 {
    margin-bottom: 49px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-50 {
    margin-bottom: 50px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-50 {
    margin-bottom: 50px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-51 {
    margin-bottom: 51px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-51 {
    margin-bottom: 51px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-52 {
    margin-bottom: 52px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-52 {
    margin-bottom: 52px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-53 {
    margin-bottom: 53px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-53 {
    margin-bottom: 53px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-54 {
    margin-bottom: 54px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-54 {
    margin-bottom: 54px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-55 {
    margin-bottom: 55px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-55 {
    margin-bottom: 55px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-56 {
    margin-bottom: 56px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-56 {
    margin-bottom: 56px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-57 {
    margin-bottom: 57px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-57 {
    margin-bottom: 57px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-58 {
    margin-bottom: 58px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-58 {
    margin-bottom: 58px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-59 {
    margin-bottom: 59px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-59 {
    margin-bottom: 59px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-60 {
    margin-bottom: 60px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-60 {
    margin-bottom: 60px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-61 {
    margin-bottom: 61px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-61 {
    margin-bottom: 61px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-62 {
    margin-bottom: 62px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-62 {
    margin-bottom: 62px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-63 {
    margin-bottom: 63px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-63 {
    margin-bottom: 63px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-64 {
    margin-bottom: 64px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-64 {
    margin-bottom: 64px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-65 {
    margin-bottom: 65px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-65 {
    margin-bottom: 65px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-66 {
    margin-bottom: 66px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-66 {
    margin-bottom: 66px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-67 {
    margin-bottom: 67px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-67 {
    margin-bottom: 67px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-68 {
    margin-bottom: 68px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-68 {
    margin-bottom: 68px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-69 {
    margin-bottom: 69px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-69 {
    margin-bottom: 69px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-70 {
    margin-bottom: 70px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-70 {
    margin-bottom: 70px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-71 {
    margin-bottom: 71px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-71 {
    margin-bottom: 71px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-72 {
    margin-bottom: 72px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-72 {
    margin-bottom: 72px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-73 {
    margin-bottom: 73px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-73 {
    margin-bottom: 73px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-74 {
    margin-bottom: 74px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-74 {
    margin-bottom: 74px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-75 {
    margin-bottom: 75px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-75 {
    margin-bottom: 75px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-76 {
    margin-bottom: 76px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-76 {
    margin-bottom: 76px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-77 {
    margin-bottom: 77px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-77 {
    margin-bottom: 77px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-78 {
    margin-bottom: 78px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-78 {
    margin-bottom: 78px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-79 {
    margin-bottom: 79px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-79 {
    margin-bottom: 79px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-80 {
    margin-bottom: 80px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-80 {
    margin-bottom: 80px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-81 {
    margin-bottom: 81px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-81 {
    margin-bottom: 81px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-82 {
    margin-bottom: 82px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-82 {
    margin-bottom: 82px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-83 {
    margin-bottom: 83px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-83 {
    margin-bottom: 83px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-84 {
    margin-bottom: 84px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-84 {
    margin-bottom: 84px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-85 {
    margin-bottom: 85px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-85 {
    margin-bottom: 85px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-86 {
    margin-bottom: 86px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-86 {
    margin-bottom: 86px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-87 {
    margin-bottom: 87px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-87 {
    margin-bottom: 87px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-88 {
    margin-bottom: 88px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-88 {
    margin-bottom: 88px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-89 {
    margin-bottom: 89px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-89 {
    margin-bottom: 89px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-90 {
    margin-bottom: 90px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-90 {
    margin-bottom: 90px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-91 {
    margin-bottom: 91px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-91 {
    margin-bottom: 91px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-92 {
    margin-bottom: 92px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-92 {
    margin-bottom: 92px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-93 {
    margin-bottom: 93px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-93 {
    margin-bottom: 93px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-94 {
    margin-bottom: 94px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-94 {
    margin-bottom: 94px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-95 {
    margin-bottom: 95px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-95 {
    margin-bottom: 95px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-96 {
    margin-bottom: 96px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-96 {
    margin-bottom: 96px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-97 {
    margin-bottom: 97px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-97 {
    margin-bottom: 97px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-98 {
    margin-bottom: 98px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-98 {
    margin-bottom: 98px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-99 {
    margin-bottom: 99px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-99 {
    margin-bottom: 99px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-100 {
    margin-bottom: 100px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-100 {
    margin-bottom: 100px !important;
  }
}

body {
  color: #000000;
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-weight: normal;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;
  background: url("../img/common/bg.jpg");
  background-size: contain;
  background-position: center top;
  overflow-x: hidden;
}

@media screen and (max-width: 834px) {
  body.Android {
    font-family: 'Noto Serif JP', serif;
  }
}

.backtop {
  right: 0;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  display: none;
}

.header {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 22px 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media screen and (max-width: 1200px) {
  .header {
    padding: 22px 20px;
  }
}

@media screen and (max-width: 834px) {
  .header {
    padding: 10px;
  }
}

.header__left {
  text-align: left;
  flex-shrink: 0;
}

.header__logo {
  display: block;
  max-width: 106px;
}

@media screen and (max-width: 990px) {
  .header__logo {
    max-width: 80px;
  }
}

@media screen and (max-width: 834px) {
  .header__logo {
    max-width: 39px;
  }
}

.header__right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media screen and (max-width: 834px) {
  .header__right {
    align-items: flex-start;
    padding: 0;
    margin-top: -2px;
  }
}

.header__btn {
  margin: 13px 0 13px 47px;
  display: flex;
}

@media screen and (max-width: 1200px) {
  .header__btn {
    margin: 13px 0 13px 15px;
  }
}

@media screen and (max-width: 834px) {
  .header__btn {
    margin: 0;
  }
}

.header__btn:hover {
  text-decoration: none;
}

.header__btn a:hover {
  text-decoration: none;
}

.header__btn .ico {
  width: 66px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.37);
  border-right: none;
}

@media screen and (max-width: 990px) {
  .header__btn .ico {
    width: 52px;
  }
}

@media screen and (max-width: 834px) {
  .header__btn .ico {
    border: none;
    background-color: transparent;
    margin-right: 11px;
    margin-top: -1px;
    width: 22px;
    height: 22px;
  }
}

.header__btn .ico img {
  max-width: 29px;
}

@media screen and (max-width: 990px) {
  .header__btn .ico img {
    max-width: 22px;
  }
}

@media screen and (max-width: 834px) {
  .header .nav-menu {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 99;
    padding: 0;
    background-color: #313131;
  }
}

.header .nav-menu .show-menu {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-top: 7px;
}

@media screen and (max-width: 834px) {
  .header .nav-menu .show-menu {
    display: block;
    margin-top: 25.4%;
  }
}

.header .nav-menu .show-menu > .item {
  padding: 11px 25px;
  position: relative;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 1200px) {
  .header .nav-menu .show-menu > .item {
    padding: 11px 15px;
  }
}

@media screen and (max-width: 990px) {
  .header .nav-menu .show-menu > .item {
    padding: 11px 12px;
  }
}

@media screen and (max-width: 834px) {
  .header .nav-menu .show-menu > .item {
    margin: 15px 0 0;
    padding: 0;
  }
}

.header .nav-menu .show-menu > .item > a {
  display: block;
  font-size: 16px;
  font-size: 1.6rem;
  color: #ffffff;
  padding: 0 0;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 990px) {
  .header .nav-menu .show-menu > .item > a {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 834px) {
  .header .nav-menu .show-menu > .item > a {
    text-align: center;
    position: relative;
    letter-spacing: 0;
  }
}

.header .nav-menu .show-menu > .item > a:focus {
  outline: none;
}

.header .nav-menu .show-menu > .item > a:hover {
  text-decoration: none;
}

.header .nav-menu .show-menu > .item .sub-menu {
  position: absolute;
  top: 100%;
  left: -20px;
  right: -20px;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1;
}

@media screen and (min-width: 835px) {
  .header .nav-menu .show-menu > .item .sub-menu {
    display: none !important;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
  }
}

@media screen and (max-width: 834px) {
  .header .nav-menu .show-menu > .item .sub-menu {
    /*display: none;*/
    position: static;
    background: none;
    border-bottom: none;
    text-align: center;
  }
}

.header .nav-menu .show-menu > .item .sub-menu li {
  padding: 0 10px;
}

@media screen and (max-width: 834px) {
  .header .nav-menu .show-menu > .item .sub-menu li {
    padding: 0;
    margin-top: 15px;
  }
}

.header .nav-menu .show-menu > .item .sub-menu li a {
  display: block;
  color: white;
  font-size: 14px;
  font-size: 1.4rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  text-align: center;
  padding: 5px 0;
}

@media screen and (min-width: 835px) {
  .header .nav-menu .show-menu > .item .sub-menu li a:hover {
    text-decoration: none;
    opacity: 0.6;
  }
}

@media screen and (max-width: 834px) {
  .header .nav-menu .show-menu > .item .sub-menu li a {
    border-bottom: none;
    padding: 0;
  }
}

.header .nav-menu .show-menu > .item .sub-menu li:last-child a {
  border-bottom: none;
}

@media screen and (min-width: 835px) {
  .header .nav-menu .show-menu > .item:hover > a:hover {
    opacity: 0.6;
  }
}

@media screen and (min-width: 835px) {
  .header .nav-menu .show-menu > .item:hover .sub-menu {
    display: block !important;
  }
}

.header .mobile-icon {
  width: 15px;
  height: 17px;
  cursor: pointer;
  z-index: 5;
  display: none;
  position: relative;
}

@media screen and (max-width: 834px) {
  .header .mobile-icon {
    display: block;
  }
}

.header .mobile-icon span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  font-size: 0;
  user-select: none;
  transition: background-color 0.3s;
}

.header .mobile-icon span:before, .header .mobile-icon span:after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  transition: transform 0.3s;
}

.header .mobile-icon span:before {
  transform: translateY(-250%);
  top: -2px;
}

.header .mobile-icon span:after {
  transform: translateY(250%);
  bottom: -2px;
}

.header .close-menu {
  width: 22px;
  height: 22px;
  transition: background .5s;
  position: fixed;
  right: 20px;
  top: 30px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 100;
  display: none;
}

@media screen and (max-width: 834px) {
  .header .close-menu {
    display: block;
  }
}

.header .close-menu span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 100%;
  height: 3px;
  font-size: 0;
  user-select: none;
}

.header .close-menu span:before, .header .close-menu span:after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  transition: transform 0.3s;
  border-radius: 3px;
}

.header .close-menu span:before {
  transform: translateY(0) rotate(45deg);
  top: 2px;
}

.header .close-menu span:after {
  transform: translateY(0) rotate(-45deg);
  bottom: -2px;
}

.footer {
  background-color: #313131;
  padding: 69px 0 28px;
}

@media screen and (max-width: 834px) {
  .footer {
    padding: 30px 0 4px;
  }
}

.footer__top {
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  padding-bottom: 54px;
}

@media screen and (max-width: 834px) {
  .footer__top {
    padding-bottom: 31px;
  }
}

.footer__row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -51px;
}

@media screen and (max-width: 990px) {
  .footer__row {
    margin: 0 -25px;
  }
}

@media screen and (max-width: 834px) {
  .footer__row {
    margin: 0 -10px;
  }
}

@media screen and (max-width: 640px) {
  .footer__row {
    margin: 0;
  }
  .footer__row:first-child .footer__col {
    margin-bottom: 10px;
  }
  .footer__row:first-child .footer__col:first-child {
    order: 1;
    margin-bottom: 28px;
  }
}

.footer__col {
  width: 50%;
  padding: 0 49px;
}

@media screen and (max-width: 990px) {
  .footer__col {
    padding: 0 25px;
  }
}

@media screen and (max-width: 834px) {
  .footer__col {
    padding: 0 10px;
  }
}

@media screen and (max-width: 640px) {
  .footer__col {
    width: 100%;
    padding: 0;
  }
}

.footer__list {
  margin: 20px 0 40px;
}

@media screen and (max-width: 834px) {
  .footer__list {
    margin: 20px 0;
  }
}

@media screen and (max-width: 640px) {
  .footer__list {
    margin: 0;
  }
}

.footer__list > li {
  color: #ffffff;
  margin-bottom: 28px;
}

@media screen and (max-width: 834px) {
  .footer__list > li {
    margin-bottom: 15px;
  }
}

.footer__list > li .ttl {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.5;
  letter-spacing: 0.05em;
  margin-bottom: 2px;
}

@media screen and (max-width: 834px) {
  .footer__list > li .ttl {
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 1.43;
    margin-bottom: 0;
  }
}

.footer__list > li .txt {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 2.15;
}

@media screen and (max-width: 834px) {
  .footer__list > li .txt {
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 1.43;
  }
}

.footer__payment {
  text-align: center;
}

@media screen and (max-width: 640px) {
  .footer__payment {
    border-radius: 7px;
    overflow: hidden;
    margin-top: 12px;
  }
}

.footer__middle {
  padding: 46px 0 48px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

@media screen and (max-width: 834px) {
  .footer__middle {
    padding: 31px 0 17px;
  }
}

.footer__logo {
  max-width: 124px;
  margin-bottom: 30px;
  flex-shrink: 0;
}

@media screen and (max-width: 834px) {
  .footer__logo {
    max-width: 100px;
    margin-bottom: 26px;
  }
}

.footer__menu {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 640px) {
  .footer__menu > li {
    width: 100%;
    text-align: center;
    margin-bottom: 6px;
  }
}

.footer__menu > li:not(:first-child) {
  margin-left: 25px;
  padding-left: 28px;
  position: relative;
}

@media screen and (max-width: 834px) {
  .footer__menu > li:not(:first-child) {
    margin-left: 15px;
    padding-left: 15px;
  }
}

@media screen and (max-width: 640px) {
  .footer__menu > li:not(:first-child) {
    margin-left: 0;
    padding-left: 0;
  }
}

.footer__menu > li:not(:first-child):before {
  content: '';
  position: absolute;
  z-index: 1;
  left: 0;
  top: 8px;
  width: 1px;
  height: 16px;
  background-color: #fff;
}

@media screen and (max-width: 834px) {
  .footer__menu > li:not(:first-child):before {
    height: 10px;
    top: 7px;
  }
}

@media screen and (max-width: 640px) {
  .footer__menu > li:not(:first-child):before {
    display: none;
  }
}

.footer__menu > li > a {
  display: inline-block;
  color: #ffffff;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2.25;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 834px) {
  .footer__menu > li > a {
    letter-spacing: 0;
    font-size: 11px;
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 640px) {
  .footer__menu > li > a {
    text-decoration: underline;
  }
}

.footer__menu > li > a:hover {
  text-decoration: underline;
}

.footer__bottom {
  text-align: center;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.79;
  letter-spacing: 0.05em;
  color: #ffffff;
  padding: 20px 0;
}

@media screen and (max-width: 834px) {
  .footer__bottom {
    font-size: 11px;
    font-size: 1.1rem;
    padding: 15px 0;
  }
}

.breadcrumb {
  font-size: 13px;
  font-size: 1.3rem;
  color: #000;
  margin-bottom: 62px;
}

.breadcrumb .inner {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
}

.breadcrumb a {
  color: inherit;
}

.breadcrumb span {
  margin-left: 14px;
}

.breadcrumb i {
  margin-left: 10px;
}

@media screen and (max-width: 834px) {
  .breadcrumb {
    display: none;
  }
}

.inner {
  width: 100%;
  display: block;
  max-width: 1040px;
  padding: 0 20px;
  margin: 0 auto;
}

.clearfix {
  *zoom: 1;
}

.clearfix:before {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

a {
  text-decoration: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (min-width: 835px) {
  a[href^="tel"] {
    pointer-events: none;
  }
}

@media screen and (max-width: 834px) {
  .pc {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .pc-ipd {
    display: none !important;
  }
}

@media screen and (max-width: 990px) {
  .pc-lg {
    display: none !important;
  }
}

@media screen and (max-width: 640px) {
  .pc-sm {
    display: none !important;
  }
}

@media screen and (max-width: 374px) {
  .pc-xs {
    display: none !important;
  }
}

@media screen and (min-width: 835px) {
  .sp {
    display: none !important;
  }
}

@media screen and (min-width: 1025px) {
  .sp-ipd {
    display: none !important;
  }
}

@media screen and (min-width: 991px) {
  .sp-lg {
    display: none !important;
  }
}

@media screen and (min-width: 641px) {
  .sp-sm {
    display: none !important;
  }
}

@media screen and (min-width: 375px) {
  .sp-xs {
    display: none !important;
  }
}

.ttl-comp01 {
  text-align: center;
  font-size: 27px;
  font-size: 2.7rem;
  padding-bottom: 34px;
  margin-bottom: 27px;
  position: relative;
}

.ttl-comp01:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 45px;
  height: 2px;
  background: #7d0000;
}

@media screen and (max-width: 834px) {
  .ttl-comp01 {
    font-size: 18px;
    font-size: 1.8rem;
    padding-bottom: 16px;
  }
}

@media screen and (min-width: 835px) {
  .ttl-comp01--sm {
    font-size: 26px;
    font-size: 2.6rem;
    padding-bottom: 32px;
    margin-bottom: 25px;
  }
}

.ttl-comp04 {
  font-size: 25px;
  font-size: 2.5rem;
  display: flex;
  align-items: flex-end;
  margin-bottom: 37px;
  letter-spacing: 0.1em;
}

.ttl-comp04 span {
  font-size: 14px;
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  margin: 0 0 5px 17px;
}

@media screen and (max-width: 834px) {
  .ttl-comp04 {
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: 17px;
  }
  .ttl-comp04 span {
    margin: 0 0 1px 9px;
    font-size: 10px;
    font-size: 1rem;
  }
}

.ttl-com06 {
  text-align: center;
  font-size: 36px;
  font-size: 3.6rem;
  padding-bottom: 24px;
  position: relative;
}

.ttl-com06:after {
  content: "";
  position: absolute;
  width: 45px;
  height: 2px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #7d0000;
}

@media screen and (max-width: 834px) {
  .ttl-com06 {
    font-size: 18px;
    font-size: 1.8rem;
    padding-bottom: 19px;
    margin-bottom: 10px;
  }
}

.txt-basic {
  line-height: 1.8;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 834px) {
  .txt-basic {
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 1.6;
  }
}

.txt-center {
  text-align: center;
}

.txt-right {
  text-align: right;
}

.txt-left {
  text-align: left;
}

.txt-vertical {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

.btn {
  width: 246px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  background-color: #880101;
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 0.1em;
  position: relative;
  border-radius: 0;
  padding: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 834px) {
  .btn {
    font-size: 11px;
    font-size: 1.1rem;
    width: 154px;
    height: 38px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .btn {
    padding-top: 8px;
  }
}

.btn span {
  display: inline-block;
  position: relative;
  padding-right: 30px;
}

@media screen and (max-width: 834px) {
  .btn span {
    padding-right: 24px;
  }
}

.btn span:after {
  content: '';
  position: absolute;
  background: url("../img/common/ico-next.png") no-repeat center/contain;
  width: 14px;
  height: 16px;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

@media screen and (max-width: 834px) {
  .btn span:after {
    width: 7px;
    height: 8px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .btn span:after {
    top: 40%;
  }
}

.btn:hover {
  background-color: #ffffff;
  border-color: #880101;
  color: #880101;
  opacity: 1;
  text-decoration: none;
}

.btn:hover > span:after {
  background-image: url("../img/common/ico-next-red.png");
}

.btn--sm {
  width: 222px;
  height: 40px;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 990px) {
  .btn--sm {
    width: 176px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .btn--sm {
    padding-top: 6px;
  }
}

.btn--sm:after {
  width: 13px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .btn--sm:after {
    top: 36%;
  }
}

.btn--lg {
  border-width: 2px;
  width: 460px;
  height: 80px;
  font-size: 23px;
  font-size: 2.3rem;
}

@media screen and (max-width: 834px) {
  .btn--lg {
    width: 280px;
    height: 49px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.btn--lg span {
  padding-right: 46px;
}

@media screen and (max-width: 834px) {
  .btn--lg span {
    padding-right: 28px;
  }
}

.btn-comp03 {
  display: inline-flex;
  justify-content: center;
  padding: 14px 10px;
  text-align: center;
  min-width: 285px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  color: #000;
  font-size: 20px;
  font-size: 2rem;
}

.btn-comp03:after {
  content: "";
  display: inline-block;
  transform: translateY(6px);
  width: 13px;
  height: 15px;
  margin-left: 30px;
  background: url(../img/product/ico-right.png) no-repeat;
}

.btn-comp03:focus {
  text-decoration: none;
  color: #000;
}

.btn-comp03:hover {
  background-color: #000000;
  border-color: #000000;
  color: #ffffff;
  text-decoration: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .btn-comp03 {
    padding-top: 20px;
    padding-bottom: 12px;
  }
  .btn-comp03:after {
    transform: translateY(2px);
  }
}

.btn-comp03--style02:after {
  display: none;
}

@media screen and (max-width: 834px) {
  .btn-comp03 {
    width: 100%;
    min-width: 100%;
    padding: 9px 10px;
    margin-bottom: 20px;
    font-size: 10px;
    font-size: 1rem;
  }
}

.btn-comp04 {
  text-align: center;
  font-size: 11px;
  font-size: 1.1rem;
  padding-bottom: 30px;
  color: inherit;
  background: url(../img/product/ico-down.png) no-repeat bottom center;
}

@media screen and (max-width: 834px) {
  .btn-comp04 {
    padding-bottom: 14px;
  }
}

.btn-comp04:hover {
  text-decoration: none;
}

.fade-up {
  opacity: 0;
  -webkit-transform: translate(0px, 120px);
  -ms-transform: translate(0px, 120px);
  transform: translate(0px, 120px);
  -webkit-transition: opacity 1s, -webkit-transform 1s;
  transition: opacity 1s, -webkit-transform 1s;
  -o-transition: transform 1s, opacity 1s;
  transition: transform 1s, opacity 1s;
  transition: transform 1s, opacity 1s, -webkit-transform 1s;
}

.fade-up.active {
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  opacity: 1;
}

.common-block {
  position: relative;
  height: 470px;
  overflow: hidden;
}

@media screen and (max-width: 834px) {
  .common-block {
    height: 160px;
  }
}

.common-block .inner {
  z-index: 2;
  position: absolute;
  top: 44.5%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}

@media screen and (max-width: 834px) {
  .common-block .inner {
    top: 50%;
  }
}

.common-block img {
  height: 600px;
  width: 100%;
  object-fit: cover;
  font-family: "object-fit:cover";
}

@media screen and (max-width: 834px) {
  .common-block img {
    height: 160px;
  }
}

.common-block__ttl {
  color: #ffffff;
}

.common-block__ttl span {
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.5;
  letter-spacing: 0.3em;
  display: block;
  margin-bottom: 11px;
}

@media screen and (max-width: 834px) {
  .common-block__ttl span {
    font-size: 14px;
    font-size: 1.4rem;
    letter-spacing: 0;
    line-height: 1.7;
    margin-bottom: 0;
  }
}

.common-block__ttl small {
  font-size: 17px;
  font-size: 1.7rem;
  letter-spacing: 0.1em;
  display: block;
}

@media screen and (max-width: 834px) {
  .common-block__ttl small {
    font-weight: bold;
    font-size: 10px;
    font-size: 1rem;
  }
}

@media screen and (min-width: 835px) {
  .common-block--style02 .inner {
    top: 52.7%;
  }
  .common-block--style02 img {
    height: 705px;
  }
}

@media screen and (min-width: 835px) {
  .common-block--style03 .inner {
    top: 49.6%;
  }
  .common-block--style03 img {
    height: 700px;
  }
}

.block-rec {
  position: relative;
  color: #ffffff;
  border: 2px solid #ffffff;
  max-width: 452px;
}

.block-rec__img img {
  width: 100%;
}

.block-rec__content {
  position: absolute;
  top: 47%;
  transform: translateY(-50%);
  right: 42px;
  left: 42px;
  text-align: right;
}

@media screen and (max-width: 834px) {
  .block-rec__content {
    right: 16px;
    left: 16px;
  }
}

.block-rec__ttl {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.45;
}

@media screen and (max-width: 834px) {
  .block-rec__ttl {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.block-rec__txt {
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 834px) {
  .block-rec__txt {
    font-size: 10px;
    font-size: 1rem;
  }
}

.block-rec--black {
  color: #000000;
}

.block-rec--black .item__content {
  right: 28px;
  left: 28px;
}

.sec-main-virual {
  position: relative;
  margin-bottom: 94px;
}

@media screen and (max-width: 834px) {
  .sec-main-virual {
    margin-bottom: 36px;
  }
}

.sec-banner {
  margin-bottom: 111px;
}

@media screen and (max-width: 834px) {
  .sec-banner {
    margin-bottom: 2px;
  }
}

.sec-bnr {
  margin-bottom: 82px;
  text-align: center;
}

@media screen and (max-width: 834px) {
  .sec-bnr {
    margin-bottom: 36px;
  }
}

.sec-message {
  margin-bottom: 73px;
}

@media screen and (max-width: 834px) {
  .sec-message {
    margin-bottom: 28px;
  }
}

.sec-line-up {
  margin-bottom: 76px;
}

@media screen and (max-width: 834px) {
  .sec-line-up {
    margin-bottom: 17px;
  }
  .sec-line-up .inner {
    padding-right: 0;
  }
}

.sec-purchase {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 64px 0 67px;
}

@media screen and (max-width: 834px) {
  .sec-purchase {
    padding: 23px 0 17px;
  }
}

.sec-food {
  position: relative;
}

.sec-food .inner {
  position: absolute;
  top: 49px;
  bottom: 40px;
  left: 0;
  right: 0;
  z-index: 5;
}

@media screen and (max-width: 834px) {
  .sec-food .inner {
    top: 19px;
    bottom: 19px;
  }
}

.sec-gift-rapping {
  position: relative;
}

.sec-gift-rapping:before {
  content: '';
  position: absolute;
  z-index: -1;
  bottom: -130px;
  left: -53px;
  background: url("../img/index/ico-mark.png") no-repeat center/contain;
  width: 399px;
  height: 400px;
}

@media screen and (max-width: 834px) {
  .sec-gift-rapping:before {
    display: none;
  }
}

.slider-home .item {
  outline: none !important;
  position: relative;
}

.slider-home .item__img img {
  width: 100%;
  height: 800px;
  object-fit: cover;
  font-family: "object-fit:cover;";
}

@media screen and (max-width: 834px) {
  .slider-home .item__img img {
    height: 400px;
  }
}

.slider-home .item__ttl {
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 1.5;
  color: #ffffff;
  letter-spacing: 0.2em;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  margin-right: 59px;
}

@media screen and (max-width: 834px) {
  .slider-home .item__ttl {
    margin-right: 0;
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.47;
  }
}

.slider-home .item .inner {
  position: absolute;
  z-index: 2;
  top: 130px;
  left: 0;
  right: 0;
  /*bottom: 255px;*/
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 834px) {
  .slider-home .item .inner {
    justify-content: center;
    top: 63px;
    right: 10px;
    bottom: 137px;
  }
}

.slider-home .slick-dots {
  bottom: -51px;
}

@media screen and (max-width: 834px) {
  .slider-home .slick-dots {
    bottom: -23px;
  }
}

.slider-home .slick-dots li {
  margin: 0 5px;
}

.slider-home .slick-dots li.slick-active button {
  background-color: #880101;
}

.slider-home .slick-dots li button {
  padding: 0;
  width: 100px;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 834px) {
  .slider-home .slick-dots li button {
    width: 70px;
  }
}

.slider-home .slick-dots li button:before {
  display: none;
}

.main-virual__block {
  position: absolute;
  bottom: 49px;
  left: 0;
  right: 0;
  z-index: 10;
}

@media screen and (max-width: 834px) {
  .main-virual__block {
    bottom: 17px;
  }
}

.main-virual__block .inner {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media screen and (max-width: 834px) {
  .main-virual__block .inner {
    align-items: center;
  }
}

.main-virual__img {
  width: 268px;
  margin-bottom: 31px;
  position: relative;
  right: -10px;
}

@media screen and (max-width: 834px) {
  .main-virual__img {
    width: 137px;
    right: auto;
    margin-bottom: 19px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .main-virual__img {
    height: 115px;
  }
}

@media screen and (max-width: 834px) {
  .slider-banner {
    display: block;
  }
}

.slider-banner .item {
  padding: 0 32px;
  max-width: 820px;
}

@media screen and (max-width: 834px) {
  .slider-banner .item {
    padding: 0 20px 15px;
  }
}

.slider-banner .item img {
  width: 100%;
}

.slider-banner .slick-dots {
  bottom: -47px;
}

.slider-banner .slick-dots li {
  margin: 0 5px;
}

.slider-banner .slick-dots li.slick-active button {
  background-color: #880101;
}

.slider-banner .slick-dots li button {
  padding: 0;
  width: 100px;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.2);
}

.slider-banner .slick-dots li button:before {
  display: none;
}

.message__row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 75px;
  position: relative;
}

@media screen and (max-width: 834px) {
  .message__row {
    padding-bottom: 30px;
    max-width: 280px;
    margin: 0 auto;
  }
}

.message__row:after {
  content: '';
  position: absolute;
  z-index: -1;
  right: -235px;
  bottom: -125px;
  background: url("../img/index/ico-mark.png") no-repeat center/contain;
  width: 321px;
  height: 321px;
}

@media screen and (max-width: 834px) {
  .message__row:after {
    width: 70px;
    height: 70px;
    bottom: auto;
    right: -13px;
    top: 52%;
    transform: translateY(-50%);
  }
}

.message__left {
  width: 20.5%;
}

@media screen and (max-width: 834px) {
  .message__left {
    width: 100%;
    order: 1;
    margin-top: 18px;
    display: flex;
  }
}

.message__img {
  margin: 0 0 35px 2px;
  max-width: 200px;
}

@media screen and (max-width: 834px) {
  .message__img {
    margin: 0;
    padding-left: 9px;
    max-width: none;
    width: 126px;
    flex-shrink: 0;
  }
}

.message__wrap {
  display: flex;
  margin-left: 6px;
}

@media screen and (max-width: 834px) {
  .message__wrap {
    margin-left: 0;
    align-items: flex-end;
    width: calc(100% - 126px);
    padding-left: 6px;
  }
}

.message__stamp {
  width: 42px;
  flex-shrink: 0;
}

@media screen and (max-width: 834px) {
  .message__stamp {
    width: 27px;
  }
}

.message__content {
  display: flex;
  width: calc(100% - 42px);
  height: 152px;
}

@media screen and (max-width: 834px) {
  .message__content {
    height: 117px;
    margin-left: 0;
    width: calc(100% - 27px);
  }
}

.message__txt {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.72;
  width: calc(100% - 35px);
}

@media screen and (max-width: 990px) {
  .message__txt {
    font-size: 12px;
    font-size: 1.2rem;
    width: calc(100% - 32px);
  }
}

@media screen and (max-width: 834px) {
  .message__txt {
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 1.9;
  }
}

.message__heading {
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 0.1em;
  padding-left: 10px;
  width: 35px;
}

@media screen and (max-width: 990px) {
  .message__heading {
    font-size: 16px;
    font-size: 1.6rem;
    padding-left: 3px;
    width: 32px;
  }
}

@media screen and (max-width: 834px) {
  .message__heading {
    font-weight: 400;
    font-size: 12px;
    font-size: 1.2rem;
    margin: 0;
    padding: 0 8px;
    flex-shrink: 0;
  }
}

.message__center {
  width: 47.5%;
  font-size: 19.5px;
  font-size: 1.95rem;
  line-height: 2.06;
  letter-spacing: 0.14em;
  height: 400px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 990px) {
  .message__center {
    width: 52.5%;
    font-size: 19px;
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 834px) {
  .message__center {
    width: 60%;
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 1.9;
    height: 260px;
    letter-spacing: 0.04em;
    padding-top: 3px;
  }
}

.message__right {
  width: 32%;
  display: flex;
  margin-top: -5px;
}

@media screen and (max-width: 990px) {
  .message__right {
    width: 27%;
  }
}

@media screen and (max-width: 834px) {
  .message__right {
    width: 40%;
    margin-top: 0;
    align-items: flex-start;
  }
}

.message__ttl {
  font-size: 40.08px;
  font-size: 4.008rem;
  line-height: 1.53;
  letter-spacing: 0.05em;
  width: calc(100% - 60px);
  height: 346px;
  padding-right: 30px;
  padding-top: 5px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 990px) {
  .message__ttl {
    font-size: 26px;
    font-size: 2.6rem;
    width: calc(100% - 50px);
    padding-right: 15px;
  }
}

@media screen and (max-width: 834px) {
  .message__ttl {
    font-size: 17px;
    font-size: 1.7rem;
    line-height: 1.48;
    width: calc(100% - 32px);
    padding-right: 16px;
    padding: 9px 16px 0 0;
    height: 260px;
  }
}

.message__greeting {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 24px;
  font-size: 2.4rem;
  letter-spacing: 0.1em;
  /*padding-top: 8px;*/
  margin-top: 5px;
  width: 60px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 990px) {
  .message__greeting {
    width: 50px;
  }
}

@media screen and (max-width: 834px) {
  .message__greeting {
    font-size: 11px;
    font-size: 1.1rem;
    width: 32px;
    padding: 5px 7px 0 5px;
    height: 210px;
  }
}

.message__greeting .en {
  font-size: 15px;
  font-size: 1.5rem;
  padding-top: 28px;
}

@media screen and (max-width: 834px) {
  .message__greeting .en {
    font-size: 10px;
    font-size: 1rem;
    padding-top: 8px;
  }
}

.line-up__ttl {
  font-size: 25px;
  font-size: 2.5rem;
  line-height: 1.6412;
  margin-bottom: 37px;
}

@media screen and (max-width: 834px) {
  .line-up__ttl {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.43;
    margin-bottom: 13px;
  }
}

.line-up__ttl .en {
  font-size: 14px;
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  margin-left: 3px;
}

@media screen and (max-width: 834px) {
  .line-up__ttl .en {
    font-size: 10px;
    font-size: 1rem;
    letter-spacing: 0.05em;
    margin-left: 10px;
  }
}

.line-up__list {
  /*display: flex;
		margin: 0 -27px;*/
  position: static;
}

@media screen and (max-width: 834px) {
  .line-up__list {
    display: block;
    margin: 0;
  }
}

.line-up__list .slick-list {
  overflow: inherit;
}

.line-up__list .slick-disabled {
  opacity: 0.5;
  cursor: default;
}

.line-up__list .item {
  /*width: 33.33333%;
			padding: 0 27px;*/
  width: 280px;
  margin-right: 60px;
}

@media screen and (max-width: 990px) {
  .line-up__list .item {
    width: 230px;
    margin-right: 30px;
  }
}

@media screen and (max-width: 834px) {
  .line-up__list .item {
    width: 194px;
    padding: 0 9px;
    margin-right: 14px;
  }
}

.line-up__list .item:focus {
  outline: none;
}

.line-up__list .item__img {
  text-align: center;
  margin-bottom: 18px;
}

@media screen and (max-width: 834px) {
  .line-up__list .item__img {
    margin-bottom: 11px;
  }
}

.line-up__list .item__img:focus {
  outline: none;
}

.line-up__list .item__ttl {
  display: block;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.5;
  margin-bottom: 12px;
  letter-spacing: 2px;
  color: #000000;
}

.line-up__list .item__ttl:hover {
  text-decoration: underline;
}

.line-up__list .item__ttl:focus {
  outline: none;
}

@media screen and (max-width: 834px) {
  .line-up__list .item__ttl {
    font-size: 12px;
    font-size: 1.2rem;
    padding-left: 5px;
    margin-bottom: 8px;
  }
}

.line-up__list .item__txt {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: #000000;
}

@media screen and (max-width: 834px) {
  .line-up__list .item__txt {
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 1.71;
    padding-left: 3px;
  }
}

.line-up__list .item__txt:focus {
  outline: none;
}

.line-up__list .slick-arrow {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0;
  z-index: 50;
}

@media screen and (max-width: 834px) {
  .line-up__list .slick-arrow {
    width: 26px;
    height: 26px;
  }
}

.line-up__list .slick-arrow:before {
  display: none;
}

.line-up__list .slick-arrow.slick-prev {
  left: 0;
  background: url("../img/product/arrow-prev.png") no-repeat;
  background-size: 100% auto;
}

.line-up__list .slick-arrow.slick-next {
  right: 0;
  background: url("../img/product/arrow-next.png") no-repeat;
  background-size: 100% auto;
}

.purchase__top {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 40px;
  margin-bottom: 60px;
}

@media screen and (max-width: 834px) {
  .purchase__top {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 11px;
  }
}

.purchase__top .btn {
  margin-bottom: 31px;
}

@media screen and (max-width: 834px) {
  .purchase__top .btn {
    margin-bottom: 9px;
  }
}

.purchase__txt {
  font-size: 15px;
  font-size: 1.5rem;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 834px) {
  .purchase__txt {
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 1.9;
  }
}

.purchase__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 39px;
}

@media screen and (max-width: 834px) {
  .purchase__list {
    margin: 0 0 3px;
  }
}

.purchase__list > li {
  width: 50%;
  padding: 0 20px;
}

@media screen and (max-width: 834px) {
  .purchase__list > li {
    padding: 0 10px;
  }
}

@media screen and (max-width: 640px) {
  .purchase__list > li {
    width: 100%;
    padding: 0;
    margin-bottom: 7px;
  }
  .purchase__list > li:first-child {
    order: 1;
  }
}

.purchase__links {
  display: flex;
  justify-content: flex-end;
  padding: 0 23px;
}

@media screen and (max-width: 834px) {
  .purchase__links {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

.purchase__links > li {
  margin-left: 50px;
}

@media screen and (max-width: 834px) {
  .purchase__links > li {
    margin-left: 0;
    margin-bottom: 2px;
  }
}

.purchase__links > li:hover > a {
  text-decoration: none;
}

.purchase__links > li > a {
  font-size: 16px;
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  text-decoration: underline;
  color: #000000;
}

@media screen and (max-width: 834px) {
  .purchase__links > li > a {
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 1.9;
  }
}

@media screen and (max-width: 834px) {
  .brand-concept__list {
    margin: 27px auto 14px;
    max-width: 280px;
  }
}

.brand-concept__list .item {
  display: flex;
  position: relative;
  margin-bottom: 3px;
}

@media screen and (max-width: 834px) {
  .brand-concept__list .item {
    margin-bottom: 18px;
    flex-wrap: wrap;
  }
  .brand-concept__list .item:last-child {
    margin-bottom: 0;
  }
}

.brand-concept__list .item__img {
  width: calc(49.5% + 150px);
  margin-left: -150px;
}

@media screen and (min-width: 835px) and (max-width: 1320px) {
  .brand-concept__list .item__img {
    margin-left: 0;
  }
}

@media screen and (max-width: 834px) {
  .brand-concept__list .item__img {
    margin: 0 -20px 25px;
    width: calc(100% + 40px);
  }
}

.brand-concept__list .item__img img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  font-family: "object-fit:cover;";
}

@media screen and (max-width: 834px) {
  .brand-concept__list .item__img img {
    height: 248px;
  }
}

.brand-concept__list .item__img--style01, .brand-concept__list .item__img--style02, .brand-concept__list .item__img--style03 {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
}

.brand-concept__list .item__img--style01 .img, .brand-concept__list .item__img--style02 .img, .brand-concept__list .item__img--style03 .img {
  margin: 0 0 5px;
  height: 247px;
  overflow: hidden;
}

@media screen and (max-width: 834px) {
  .brand-concept__list .item__img--style01 .img, .brand-concept__list .item__img--style02 .img, .brand-concept__list .item__img--style03 .img {
    height: 123px;
    margin-bottom: 3px;
  }
}

.brand-concept__list .item__img--style01 .img img, .brand-concept__list .item__img--style02 .img img, .brand-concept__list .item__img--style03 .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit:cover;";
}

.brand-concept__list .item__img--style01 .item__img--first {
  width: 100%;
}

.brand-concept__list .item__img--style01 .item__img--last {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.brand-concept__list .item__img--style01 .item__img--last .img {
  width: calc( calc(100% - 5px) / 2);
}

@media screen and (max-width: 834px) {
  .brand-concept__list .item__img--style01 .item__img--last .img {
    width: calc( calc(100% - 3px) / 2);
  }
}

.brand-concept__list .item__img--style02, .brand-concept__list .item__img--style03 {
  justify-content: space-between;
}

.brand-concept__list .item__img--style02 .item__img--first,
.brand-concept__list .item__img--style02 .item__img--last, .brand-concept__list .item__img--style03 .item__img--first,
.brand-concept__list .item__img--style03 .item__img--last {
  width: calc( calc(100% - 5px) / 2);
}

@media screen and (max-width: 834px) {
  .brand-concept__list .item__img--style02 .item__img--first,
  .brand-concept__list .item__img--style02 .item__img--last, .brand-concept__list .item__img--style03 .item__img--first,
  .brand-concept__list .item__img--style03 .item__img--last {
    width: calc( calc(100% - 3px) / 2);
  }
}

.brand-concept__list .item__img--style02 .item__img--first .img, .brand-concept__list .item__img--style03 .item__img--first .img {
  height: 499px;
}

@media screen and (max-width: 834px) {
  .brand-concept__list .item__img--style02 .item__img--first .img, .brand-concept__list .item__img--style03 .item__img--first .img {
    height: 249px;
  }
}

.brand-concept__list .item__img--style03 .item__img--first {
  order: 2;
}

.brand-concept__list .item__img--style03 .item__img--last {
  order: 1;
}

.brand-concept__list .item__content {
  width: 50.5%;
  display: flex;
  align-items: flex-start;
  padding: 53px 5px 0 30px;
}

@media screen and (max-width: 834px) {
  .brand-concept__list .item__content {
    width: 100%;
    padding: 0;
  }
}

.brand-concept__list .item__txt {
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2.17;
  padding-top: 6px;
  height: 366px;
  width: calc(100% - 172px);
}

@media screen and (max-width: 990px) {
  .brand-concept__list .item__txt {
    font-size: 14px;
    font-size: 1.4rem;
    width: calc(100% - 146px);
  }
}

@media screen and (max-width: 834px) {
  .brand-concept__list .item__txt {
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 1.9;
    height: 220px;
    margin-left: 0;
    justify-content: flex-end;
    width: calc(100% - 99px);
  }
}

.brand-concept__list .item__ttl {
  letter-spacing: 0.05em;
  font-size: 26.6px;
  font-size: 2.66rem;
  line-height: 1.6;
  padding-top: 6px;
  height: 410px;
  padding-left: 42px;
  width: 126px;
}

@media screen and (max-width: 990px) {
  .brand-concept__list .item__ttl {
    padding-left: 15px;
    font-size: 24px;
    font-size: 2.4rem;
    width: 99px;
  }
}

@media screen and (max-width: 834px) {
  .brand-concept__list .item__ttl {
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 2;
    padding-top: 3px;
    padding-left: 10px;
    height: 230px;
    width: 70px;
  }
}

.brand-concept__list .item__lb {
  font-size: 14px;
  font-size: 1.4rem;
  letter-spacing: 0.05em;
  width: 32px;
  height: 202px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 6px;
  margin-left: 26px;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 990px) {
  .brand-concept__list .item__lb {
    margin-left: 15px;
  }
}

@media screen and (max-width: 834px) {
  .brand-concept__list .item__lb {
    font-size: 11px;
    font-size: 1.1rem;
    width: 29px;
    padding: 4px 3px;
    margin-left: 14px;
  }
}

@media screen and (min-width: 835px) {
  .brand-concept__list .item--first {
    margin-bottom: -53px;
  }
  .brand-concept__list .item--first .item__img {
    margin-top: -87px;
    margin-left: -408px;
    width: calc(49.5% + 550px);
    position: relative;
    left: -57px;
  }
}

@media screen and (min-width: 835px) and (max-width: 990px) {
  .brand-concept__list .item--first .item__img {
    left: 0;
  }
}

@media screen and (min-width: 835px) {
  .brand-concept__list .item--first .item__img img {
    height: 639px;
    object-position: right;
  }
}

@media screen and (max-width: 834px) {
  .brand-concept__list .item--first {
    margin-bottom: 16px;
  }
  .brand-concept__list .item--first .item__img {
    margin: 0 0 -11px -110px;
    width: calc(100% + 96px);
  }
  .brand-concept__list .item--first .item__img img {
    height: 254px;
    object-position: right;
  }
}

@media screen and (min-width: 835px) {
  .brand-concept__list .item:nth-child(even) {
    flex-direction: row-reverse;
  }
  .brand-concept__list .item:nth-child(even) .item__img {
    margin-left: 0;
    margin-right: -150px;
  }
  .brand-concept__list .item:nth-child(even) .item__content {
    padding: 62px 83px 0 0px;
    position: relative;
    left: -9px;
  }
}

@media screen and (min-width: 835px) and (max-width: 1320px) {
  .brand-concept__list .item:nth-child(even) .item__img {
    margin-right: -0px;
  }
}

.food__img img {
  height: 1200px;
  width: 100%;
  object-fit: cover;
  font-family: "object-fit:cover;";
}

@media screen and (max-width: 834px) {
  .food__img img {
    height: 250px;
  }
}

.food__header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.food__ttl {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: 38.83px;
  font-size: 3.883rem;
  line-height: 1.55;
  color: #ffffff;
  padding-top: 12px;
  height: 572px;
}

@media screen and (max-width: 834px) {
  .food__ttl {
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 2;
    height: 207px;
    padding-top: 4px;
    letter-spacing: 0.04em;
  }
}

.food__lb {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: 20.46px;
  font-size: 2.046rem;
  color: #ffffff;
  border-left: 1px solid #b5b5b5;
  margin: 0 65px 0 19px;
  padding: 12px 0 102px 20px;
}

@media screen and (max-width: 834px) {
  .food__lb {
    font-size: 11px;
    font-size: 1.1rem;
    margin: 0 3px 0 14px;
    padding: 4px 0 14px 12px;
  }
}

.food__lb small {
  font-size: 15px;
  font-size: 1.5rem;
  letter-spacing: 0.1em;
  margin-top: 16px;
}

@media screen and (max-width: 834px) {
  .food__lb small {
    font-size: 10px;
    font-size: 1rem;
    margin-top: 8px;
    font-weight: bold;
    letter-spacing: 0;
  }
}

.food__txt {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2.19;
  color: #ffffff;
  letter-spacing: 0.04em;
  max-width: 397px;
  display: flex;
  margin-left: auto;
  margin-right: 80px;
}

@media screen and (max-width: 834px) {
  .food__txt {
    display: none;
  }
}

.gift-rapping__list {
  display: flex;
  flex-wrap: wrap;
  margin: 106px -53px 43px;
}

@media screen and (max-width: 990px) {
  .gift-rapping__list {
    margin: 106px -20px 43px;
  }
}

@media screen and (max-width: 834px) {
  .gift-rapping__list {
    margin: 30px -10px 1px;
  }
}

.gift-rapping__list .item {
  width: 50%;
  padding: 0 53px;
  margin-bottom: 52px;
}

@media screen and (max-width: 990px) {
  .gift-rapping__list .item {
    padding: 0 20px;
  }
}

@media screen and (max-width: 834px) {
  .gift-rapping__list .item {
    padding: 0 10px;
    margin-bottom: 26px;
  }
}

.gift-rapping__list .item__img {
  margin-bottom: 32px;
}

@media screen and (max-width: 834px) {
  .gift-rapping__list .item__img {
    margin-bottom: 19px;
  }
}

.gift-rapping__list .item__img img {
  width: 100%;
}

.gift-rapping__list .item__ttl {
  display: block;
  font-size: 23px;
  font-size: 2.3rem;
  line-height: 1.875;
  letter-spacing: 0.1em;
  margin-bottom: 10px;
}

@media screen and (max-width: 834px) {
  .gift-rapping__list .item__ttl {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.43;
  }
}

.gift-rapping__list .item__txt {
  display: block;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.875;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 834px) {
  .gift-rapping__list .item__txt {
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 1.43;
  }
}

.order__list {
  margin: 100px 0 90px;
}

@media screen and (max-width: 834px) {
  .order__list {
    margin: 30px 0 33px;
  }
}

.order__list .item {
  margin-bottom: 53px;
}

@media screen and (max-width: 834px) {
  .order__list .item {
    margin-bottom: 27px;
  }
}

.order__list .item__banner {
  margin-bottom: 56px;
}

@media screen and (max-width: 834px) {
  .order__list .item__banner {
    margin-bottom: 30px;
  }
}

.order__list .item__banner img {
  width: 100%;
}

.order__list .item__wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -48px 0 -28px;
}

@media screen and (max-width: 990px) {
  .order__list .item__wrap {
    margin: 0 -20px;
  }
}

@media screen and (max-width: 834px) {
  .order__list .item__wrap {
    margin: 0;
  }
}

.order__list .item__left {
  width: 47%;
  padding: 24px 48px 0;
}

@media screen and (max-width: 990px) {
  .order__list .item__left {
    padding: 24px 20px 0;
  }
}

@media screen and (max-width: 834px) {
  .order__list .item__left {
    width: 100%;
    padding: 0;
  }
}

.order__list .item__right {
  width: 53%;
  padding: 0 48px;
}

@media screen and (max-width: 990px) {
  .order__list .item__right {
    padding: 0 20px;
  }
}

@media screen and (max-width: 834px) {
  .order__list .item__right {
    width: 100%;
    padding: 0;
  }
}

.order__list .item__img {
  margin-bottom: 10px;
}

@media screen and (max-width: 834px) {
  .order__list .item__img {
    margin: 0 10px 20px 20px;
  }
}

.order__list .item__caption {
  text-align: left;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2.17;
  letter-spacing: 0.1em;
  padding-right: 5px;
}

@media screen and (max-width: 834px) {
  .order__list .item__caption {
    display: none;
  }
}

.order__list .item__ttl {
  font-size: 28px;
  font-size: 2.8rem;
  line-height: 1.79;
  margin-bottom: 10px;
}

@media screen and (max-width: 990px) {
  .order__list .item__ttl {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media screen and (max-width: 834px) {
  .order__list .item__ttl {
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1.67;
  }
}

.order__list .item__txt {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.875;
  padding-bottom: 22px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 45px;
}

@media screen and (max-width: 834px) {
  .order__list .item__txt {
    font-size: 11px;
    font-size: 1.1rem;
    letter-spacing: 0.05em;
    line-height: 1.43;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

.order__list .item__box {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 23px;
}

@media screen and (max-width: 834px) {
  .order__list .item__box {
    justify-content: space-between;
    margin-bottom: 19px;
  }
}

.order__list .item__input {
  display: flex;
  align-items: center;
  margin-right: 26px;
}

.order__list .item__input span {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.43;
  letter-spacing: 0.05em;
  margin-right: 10px;
  padding-top: 5px;
}

@media screen and (max-width: 834px) {
  .order__list .item__input span {
    font-size: 11px;
    font-size: 1.1rem;
    padding-top: 8px;
  }
}

.order__list .item__input input {
  width: 80px;
  height: 40px;
  line-height: 37;
  border: 1px solid rgba(0, 0, 0, 0.2);
  text-align: right;
  padding: 0 15px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.43;
  font-family: "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
}

@media screen and (max-width: 834px) {
  .order__list .item__input input {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.order__list .item__payment {
  flex-shrink: 0;
}

.order__list .item__price {
  color: #c20000;
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.3;
  margin-bottom: 2px;
}

@media screen and (max-width: 834px) {
  .order__list .item__price {
    font-size: 20px;
    font-size: 2rem;
    margin-bottom: 0;
  }
}

.order__list .item__price small {
  display: inline-block;
  letter-spacing: 0.1em;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 834px) {
  .order__list .item__price small {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.order__list .item__pt {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.43;
  letter-spacing: 0.05em;
  text-align: right;
}

@media screen and (max-width: 834px) {
  .order__list .item__pt {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.order__list .item__pt strong {
  display: inline-block;
  margin-left: 5px;
  color: #c20000;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.25;
  font-weight: normal;
}

@media screen and (max-width: 834px) {
  .order__list .item__pt strong {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.order__list .item__btn {
  margin-bottom: 28px;
}

@media screen and (max-width: 834px) {
  .order__list .item__btn {
    margin-bottom: 10px;
  }
}

.order__list .item__btn .btn {
  width: 100%;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 834px) {
  .order__list .item__btn .btn {
    height: 47px;
    font-size: 14px;
    font-size: 1.4rem;
  }
  .order__list .item__btn .btn span:after {
    width: 8px;
    height: 9px;
    top: 48%;
  }
}

@media screen and (min-width: 835px) {
  .order__list .item__btn .btn span {
    padding-right: 0;
  }
  .order__list .item__btn .btn span:after {
    display: none;
  }
}

.order__list .item__more {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.79;
  letter-spacing: 0.05em;
  text-align: right;
}

@media screen and (max-width: 834px) {
  .order__list .item__more {
    letter-spacing: 0;
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 1.43;
  }
}

.order__list .item__more strong {
  display: block;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.33;
  margin-bottom: 7px;
}

@media screen and (max-width: 834px) {
  .order__list .item__more strong {
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 1.43;
    margin-bottom: 4px;
  }
}

.main-visual {
  margin-bottom: 30px;
  height: 320px;
  overflow: hidden;
}

@media screen and (max-width: 834px) {
  .main-visual {
    margin-bottom: 28px;
    height: 120px;
  }
}

@media screen and (min-width: 835px) {
  .main-visual img {
    width: 100%;
    min-height: 100%;
    font-family: 'object-fit: cover;';
    object-fit: cover;
    object-position: center;
  }
}

@media screen and (max-width: 834px) {
  .main-visual img {
    width: 100%;
    min-height: 100%;
    font-family: 'object-fit: cover;';
    object-fit: cover;
    object-position: center;
  }
}

.nav01 {
  display: flex;
  justify-content: flex-end;
  padding-right: 7px;
  margin: 0 -13px 66px;
}

@media screen and (max-width: 834px) {
  .nav01 {
    justify-content: center;
    margin: 0 -11px 32px;
  }
}

.nav01 a, .nav01 span {
  font-size: 14px;
  font-size: 1.4rem;
  color: inherit;
  padding: 0 13px;
  position: relative;
}

.nav01 a:after, .nav01 span:after {
  content: "";
  height: 15px;
  width: 1px;
  background: #000000;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .nav01 a:after, .nav01 span:after {
    top: 42%;
  }
}

@media screen and (max-width: 834px) {
  .nav01 a, .nav01 span {
    font-size: 11px;
    font-size: 1.1rem;
    padding: 0 11px;
  }
  .nav01 a:after, .nav01 span:after {
    height: 11px;
  }
}

.nav01 span {
  color: #880101;
}

.nav01 li:last-child a:after, .nav01 li:last-child span:after {
  display: none;
}

.line-up__list {
  display: flex;
}

.line-up__list--style02 {
  margin-bottom: -48px;
  margin-left: -25px;
  margin-right: -25px;
  flex-wrap: wrap;
}

@media screen and (max-width: 834px) {
  .line-up__list--style02 {
    display: flex;
    margin-left: -12px;
    margin-right: -12px;
  }
}

.line-up__list--style02 a {
  color: inherit;
}

.line-up__list--style02 .item {
  width: 33.33%;
  padding: 0 25px;
  margin-right: 0;
  margin-bottom: 48px;
}

@media screen and (max-width: 834px) {
  .line-up__list--style02 .item {
    width: 50%;
    margin-right: 0;
    padding: 0 12px;
    margin-bottom: 17px;
  }
}

.line-up__list--style02 .item__price {
  font-size: 16px;
  font-size: 1.6rem;
  text-align: right;
}

@media screen and (max-width: 834px) {
  .line-up__list--style02 .item__price {
    font-size: 11px;
    font-size: 1.1rem;
    text-align: left;
  }
}

.line-up__list--style02 .item__img {
  margin-bottom: 52px;
  padding-left: 20px;
}

@media screen and (max-width: 834px) {
  .line-up__list--style02 .item__img {
    padding-left: 0;
    margin-bottom: 15px;
  }
}

.line-up__list--style02 .item__ttl {
  margin-bottom: 8px;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 834px) {
  .line-up__list--style02 .item__ttl {
    padding-left: 0;
    font-size: 11px;
    font-size: 1.1rem;
    margin-bottom: 4px;
  }
}

.line-up__list--style02 .item__ttl--underline a {
  text-decoration: underline;
}

.line-up__list--style02 .item__ttl--underline:hover {
  text-decoration: none !important;
}

.line-up__list--style02 .item__ttl--underline:hover a {
  text-decoration: none !important;
}

.sec-category {
  padding-bottom: 130px;
}

@media screen and (max-width: 834px) {
  .sec-category {
    padding-bottom: 84px;
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 595px;
  margin: 100px auto 50px;
}

@media screen and (max-width: 834px) {
  .pagination {
    padding: 0 15px;
    margin-top: 65px;
    margin-bottom: 27px;
  }
}

.pagination li {
  width: 33.33%;
}

.pagination li.current-page {
  font-size: 14px;
  font-size: 1.4rem;
  text-align: center;
  margin-left: 15px;
  margin-top: -5px;
}

.pagination li.current-page span {
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 834px) {
  .pagination li.current-page {
    display: none;
  }
}

.pagination li.next-page {
  text-align: right;
}

.pagination li.next-page a:before, .pagination li.next-page .icon_prev_dis:before, .pagination li.next-page .icon_next_dis:before {
  transform: rotate(45deg);
  right: -15px;
  left: auto;
}

@media screen and (max-width: 834px) {
  .pagination li.next-page a:before, .pagination li.next-page .icon_prev_dis:before, .pagination li.next-page .icon_next_dis:before {
    right: -10px;
  }
}

.pagination li a, .pagination li .icon_prev_dis, .pagination li .icon_next_dis {
  font-size: 14px;
  font-size: 1.4rem;
  color: #000;
  position: relative;
}

@media screen and (max-width: 834px) {
  .pagination li a, .pagination li .icon_prev_dis, .pagination li .icon_next_dis {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.pagination li a:before, .pagination li .icon_prev_dis:before, .pagination li .icon_next_dis:before {
  content: '';
  display: block;
  width: 9px;
  height: 9px;
  border-right: 1px solid #000;
  border-top: 1px solid #000;
  position: absolute;
  top: 4px;
  left: -15px;
  transform: rotate(-135deg);
}

@media screen and (max-width: 834px) {
  .pagination li a:before, .pagination li .icon_prev_dis:before, .pagination li .icon_next_dis:before {
    width: 6px;
    height: 6px;
    left: -10px;
  }
}

.pagination li .icon_prev_dis,
.pagination li .icon_next_dis {
  opacity: 0.5;
}

.product {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 63px;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 834px) {
  .product {
    margin-bottom: 27px;
  }
}

.product__content {
  width: 42.8%;
  padding-top: 9px;
}

@media screen and (max-width: 834px) {
  .product__content {
    width: 100%;
    padding-top: 0;
  }
}

.product__slide {
  width: 57.2%;
  padding-right: 70px;
}

@media screen and (max-width: 834px) {
  .product__slide {
    width: 100%;
    padding: 0;
    margin-bottom: 16px;
  }
}

.product__slide__for {
  margin-bottom: 60px;
  padding-left: 6px;
}

@media screen and (max-width: 834px) {
  .product__slide__for img {
    display: block;
    width: 100%;
    position: relative;
    z-index: 3;
  }
}

.product__slide__for .slick-arrow {
  position: absolute;
  width: 26px;
  height: 26px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0;
  z-index: 2;
}

.product__slide__for .slick-arrow:before {
  display: none;
}

.product__slide__for .slick-arrow.slick-prev {
  left: -21px;
  background: url("../img/product/arrow-prev.png") no-repeat;
  background-size: 26px auto;
}

.product__slide__for .slick-arrow.slick-next {
  right: -21px;
  background: url("../img/product/arrow-next.png") no-repeat;
  background-size: 26px auto;
}

@media screen and (max-width: 834px) {
  .product__slide__for {
    padding-left: 0;
    margin-bottom: 10px;
  }
}

.product__slide__nav {
  margin: 0 -11px;
}

.product__slide__nav .slick-track {
  transform: translate(0) !important;
  width: 100% !important;
  display: flex;
  flex-wrap: wrap;
}

.product__slide__nav__item {
  width: 24% !important;
  padding: 0 11px;
  margin-bottom: 23px;
  cursor: pointer;
}

@media screen and (max-width: 834px) {
  .product__slide__nav__item {
    margin-bottom: 13px;
  }
}

@media screen and (max-width: 330px) {
  .product__slide__nav__item {
    width: 24% !important;
  }
}

@media screen and (max-width: 834px) {
  .product {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.product__ttl {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.458;
  margin-bottom: 35px;
}

@media screen and (max-width: 834px) {
  .product__ttl {
    font-size: 18px;
    font-size: 1.8rem;
    margin-bottom: 17px;
  }
}

.product__des {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.6;
  margin-bottom: 32px;
}

@media screen and (max-width: 834px) {
  .product__des {
    font-size: 11px;
    font-size: 1.1rem;
    margin-bottom: 15px;
  }
}

.product__review {
  text-align: right;
  padding-bottom: 37px;
  border-bottom: solid 1px #cccccc;
  margin-bottom: 34px;
}

@media screen and (max-width: 834px) {
  .product__review {
    padding-bottom: 18px;
    margin-bottom: 19px;
  }
}

.product__select {
  width: 35%;
  display: inline-flex;
  align-items: center;
  /*padding-left: 53px;*/
  font-size: 14px;
  font-size: 1.4rem;
  /*for IE10+*/
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .product__select span {
    padding-top: 4px;
  }
}

.product__select input {
  font-size: 14px;
  font-size: 1.4rem;
  width: 80px;
  height: 40px;
  border: solid 1px #bfbfbf;
  padding-left: 44px;
  margin-left: 9px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .product__select input {
    padding-top: 7px;
  }
}

.product__select input:hover, .product__select input:focus {
  outline: none;
}

@media screen and (max-width: 1200px) {
  .product__select {
    padding-left: 0;
    width: auto;
    margin-right: 20px;
  }
}

@media screen and (max-width: 834px) {
  .product__select {
    width: 100%;
    justify-content: flex-end;
    margin-right: 5px;
    margin-bottom: 12px;
    font-size: 11px;
    font-size: 1.1rem;
  }
  .product__select select {
    width: 60px;
    height: 33px;
    margin-left: 7px;
    padding-left: 39px;
  }
}

.product__buy {
  margin-bottom: 20px;
}

.product__buy li {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 8px;
}

@media screen and (max-width: 1200px) {
  .product__buy li:first-child {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 834px) {
  .product__buy li:first-child {
    justify-content: flex-end;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 834px) {
  .product__buy li {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 834px) {
  .product__buy {
    margin-bottom: 18px;
  }
}

.product__price {
  font-weight: bold;
  font-size: 23px;
  font-size: 2.3rem;
  width: 65%;
  text-align: right;
}

.product__price .red {
  color: #9c0000;
}

.product__price span {
  font-size: 14px;
  font-size: 1.4rem;
  color: #000;
  padding-right: 1em;
}

.product__price small {
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 1200px) {
  .product__price {
    width: auto;
  }
}

@media screen and (max-width: 834px) {
  .product__price {
    width: 100%;
    font-size: 17px;
    font-size: 1.7rem;
    margin-bottom: 6px;
  }
  .product__price span {
    font-size: 11px;
    font-size: 1.1rem;
  }
  .product__price small {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.product__point {
  text-align: right;
  margin-bottom: 11px !important;
}

.product__point span {
  font-size: 16px;
  font-size: 1.6rem;
  color: #a90000;
}

@media screen and (max-width: 834px) {
  .product__point {
    font-size: 11px;
    font-size: 1.1rem;
  }
  .product__point span {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.product__option {
  margin-bottom: 17px;
}

.product__option li {
  /*padding: 14px 20px;
			border:solid 1px #bfbfbf;			
			background: #ffffff;*/
  margin-bottom: 13px;
}

@media screen and (max-width: 834px) {
  .product__option li {
    /*padding: 9px 20px;*/
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 834px) {
  .product__option {
    margin-bottom: 14px;
  }
}

.product__option select {
  height: 49px;
  padding: 14px 20px;
  border: solid 1px #bfbfbf;
  background: #ffffff;
  margin-top: 10px;
  border-radius: 0;
}

@media screen and (max-width: 834px) {
  .product__option select {
    height: 35px;
    padding: 9px 20px;
    margin-top: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

.product__transition {
  text-align: right;
  font-size: 15px;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

@media screen and (max-width: 834px) {
  .product__transition {
    font-size: 11px;
    font-size: 1.1rem;
    margin-bottom: 16px;
  }
}

.product__date {
  margin-bottom: 30px;
  text-align: right;
}

@media screen and (max-width: 834px) {
  .product__date {
    margin-bottom: 12px;
  }
}

.product__sign {
  text-align: right;
  margin-bottom: 25px;
}

.product__sign a {
  text-decoration: underline;
  color: inherit;
}

.product__sign a:hover {
  text-decoration: none;
}

@media screen and (max-width: 834px) {
  .product__sign {
    margin-bottom: 5px;
  }
}

.product__info {
  text-align: right;
}

.product__info li {
  margin-bottom: 4px;
}

.product__info li a {
  color: inherit;
  text-decoration: underline;
}

.product__info li a:hover {
  text-decoration: none;
}

.product__info li:last-child {
  margin-bottom: 0;
}

.btn-buy {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  background-color: #880101;
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 0.1em;
  position: relative;
  border-radius: 0;
  padding: 0;
  margin-bottom: 28px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  border: 1px solid #FFF;
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
}

.btn-buy:hover {
  background-color: #ffffff;
  border-color: #880101;
  color: #880101;
  opacity: 1;
  text-decoration: none;
}

.btn-buy:focus {
  outline: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .btn-buy {
    padding-top: 20px;
  }
}

@media screen and (max-width: 834px) {
  .btn-buy {
    font-size: 15px;
    font-size: 1.5rem;
    padding: 7px 10px;
    margin-bottom: 24px;
  }
}

.box-sns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transform: translateX(7px);
  margin-bottom: 22px;
}

.box-sns li {
  display: inline-flex;
  align-items: center;
  margin-left: 6px;
}

.box-sns li:last-child {
  margin-left: 0;
}

@media screen and (max-width: 834px) {
  .box-sns li {
    max-width: 85px;
    overflow: hidden;
  }
}

.box-sns .b {
  transform: scale(0.5);
}

@media screen and (max-width: 834px) {
  .box-sns {
    margin-bottom: 10px;
  }
}

.resources-box {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 82px;
  padding: 41px 0 25px;
  border-top: solid 1px #cccccc;
  border-bottom: solid 1px #cccccc;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 2.14;
}

@media screen and (max-width: 834px) {
  .resources-box {
    padding-top: 24px;
    padding-bottom: 19px;
    margin-bottom: 32px;
  }
}

.resources-box__item {
  width: calc(50% - 30px);
  margin-right: 0;
}

.resources-box__item:first-of-type {
  margin-left: 0;
}

.resources-box__item:last-of-type {
  color: #a40000;
  margin-left: 50px;
  margin-right: 0;
}

@media screen and (max-width: 834px) {
  .resources-box__item {
    width: 100%;
    margin: 0 0 10px !important;
  }
}

.resources-box span {
  font-weight: bold;
}

@media screen and (max-width: 834px) {
  .resources-box {
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 1.7;
  }
}

.footer__top--black {
  border-bottom: solid 1px #a2a1a1;
  margin-bottom: 30px;
}

.footer__top--black .footer__list > li {
  color: #000;
}

.sec-payment {
  background: url(../img/product/bg-01.jpg) repeat;
  padding: 90px 0 36px;
}

@media screen and (max-width: 834px) {
  .sec-payment {
    padding: 29px 0 10px;
  }
  .sec-payment .footer__row:first-child .footer__col:first-child {
    margin-bottom: 15px;
  }
}

.sec-payment .block-rec {
  margin-bottom: 43px;
}

@media screen and (max-width: 834px) {
  .sec-payment .block-rec {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 834px) {
  .sec-payment .footer__col:nth-child(2) .footer__list li:last-child {
    margin-bottom: 2px;
  }
}

.sec-payment .footer__list {
  margin-bottom: 0;
  padding-bottom: 0;
}

.sec-payment .footer__top {
  padding-bottom: 0;
  margin-bottom: 25px;
}

@media screen and (max-width: 834px) {
  .sec-payment .footer__top {
    padding-bottom: 21px;
    margin-bottom: 15px;
  }
}

.sec-payment .purchase__links > li {
  margin: 0;
  padding: 0 45px;
}

@media screen and (max-width: 834px) {
  .sec-payment .purchase__links > li {
    text-align: center;
    padding: 0;
    margin-bottom: 10px;
    width: 100%;
  }
}

.purchase__links--style02 {
  justify-content: center;
}

.sec-review {
  padding: 82px 0 77px;
}

@media screen and (max-width: 834px) {
  .sec-review {
    padding: 30px 0 25px;
  }
}

@media screen and (min-width: 835px) {
  .sec-review--bg {
    padding: 101px 0 107px;
  }
  .sec-review--bg:after {
    content: '';
    position: absolute;
    z-index: -1;
    background: url("../img/index/ico-mark.png") no-repeat center/contain;
    width: 399px;
    height: 400px;
    right: -66px;
    top: -117px;
  }
}

@media screen and (max-width: 834px) {
  .sec-review .btn-comp04 {
    height: 28px;
    display: inline-block;
    width: 100%;
    transition: none;
    background: none;
    text-decoration: underline;
    text-underline-position: under;
  }
  .sec-review .btn-comp04:before {
    content: 'もっと見る';
  }
  .sec-review .btn-comp04.on:before {
    content: '閉じる';
  }
}

.no-review {
  margin-bottom: 35px;
}

.no-review p {
  font-size: 25px;
  font-size: 2.5rem;
  text-align: center;
  padding: 50px 0;
  border: solid 1px #bfbfbf;
}

@media screen and (max-width: 834px) {
  .no-review p {
    font-size: 14px;
    font-size: 1.4rem;
    padding: 30px 0;
  }
}

.list-review {
  margin-bottom: 33px;
}

@media screen and (max-width: 834px) {
  .list-review {
    margin-bottom: 18px;
  }
}

.list-review__item {
  margin-bottom: 20px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  padding: 29px 36px 25px;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 834px) {
  .list-review__item:nth-child(n+2) {
    display: none;
  }
}

@media screen and (max-width: 834px) {
  .list-review__item {
    padding: 13px 13px 13px;
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.list-review__item .star-img {
  max-width: 83px;
}

@media screen and (max-width: 834px) {
  .list-review__item .star-img {
    margin-bottom: 10px;
  }
}

.list-review__info {
  font-size: 14px;
  font-size: 1.4rem;
  width: 28.2%;
  letter-spacing: 0.05em;
}

.list-review__info p {
  margin-bottom: 8px;
}

@media screen and (max-width: 834px) {
  .list-review__info {
    display: none;
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.list-review__content {
  width: 71.8%;
}

.list-review__content .list-review__info {
  display: none;
}

@media screen and (max-width: 834px) {
  .list-review__content .list-review__info {
    display: block;
    width: 100%;
  }
}

.list-review__content .txt-basic {
  margin-bottom: 14px;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 834px) {
  .list-review__content .txt-basic {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 834px) {
  .list-review__content {
    width: 100%;
  }
}

.list-review__ttl {
  font-weight: bold;
  font-size: 16px;
  font-size: 1.6rem;
  margin-bottom: 3px;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 834px) {
  .list-review__ttl {
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 1.7;
  }
}

@media screen and (max-width: 834px) {
  .list-review .txt-basic {
    margin-bottom: 11px;
  }
}

.list-review__conclusion {
  text-align: right;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin-right: 25px;
}

@media screen and (max-width: 834px) {
  .list-review__conclusion {
    margin-right: 0;
  }
}

@media screen and (max-width: 834px) {
  .list-review.on .list-review__item {
    display: block;
  }
}

.star {
  display: flex;
}

@media screen and (max-width: 834px) {
  .star {
    margin-bottom: 10px;
  }
}

.star li {
  width: 15px;
  height: 15px;
  background: url(../img/product/star-02.png) no-repeat center center;
  background-size: 100% auto;
  margin-right: 2px;
}

.star li.on {
  background: url(../img/product/star-01.png) no-repeat center center;
  background-size: 100% auto;
}

@media screen and (max-width: 834px) {
  .star li {
    width: 14px;
    height: 14px;
  }
}

.line-up__list .item__ttl a {
  color: inherit;
}

.sec-other {
  padding: 80px 0 65px;
}

@media screen and (max-width: 834px) {
  .sec-other {
    padding: 30px 0 35px;
  }
}

.sec-other .line-up__list {
  width: 100%;
  display: block;
  margin: 0;
}

.sec-other .line-up__list .item {
  max-width: 315px;
}

.sec-other .line-up__list .item__img {
  margin-bottom: 35px;
}

@media screen and (max-width: 834px) {
  .sec-other .line-up__list .item__img {
    margin-bottom: 13px;
  }
}

@media screen and (max-width: 834px) {
  .sec-other .line-up__list .item__ttl {
    margin-bottom: 2px;
  }
}

.sec-other .line-up__list .slick-list {
  overflow: initial !important;
  padding: 0 8% !important;
}

.sec-other .line-up__list .slick-arrow {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 36%;
  transform: translateY(-50%);
  z-index: 10;
}

.sec-other .line-up__list .slick-arrow.slick-prev {
  left: 0;
  background: url("../img/product/arrow-prev.png") no-repeat;
  background-size: 100% auto;
}

.sec-other .line-up__list .slick-arrow.slick-next {
  right: 0;
  background: url("../img/product/arrow-next.png") no-repeat;
  background-size: 100% auto;
}

@media screen and (max-width: 834px) {
  .sec-other .line-up__list .slick-arrow {
    width: 26px;
    height: 26px;
    top: 21%;
  }
}

.sec-food--style02 .txt-basic {
  font-size: 11px;
  font-size: 1.1rem;
  line-height: 1.7;
  padding: 0 0 30px;
  margin: 20px 20px 0;
  border-bottom: solid 1px #dcdcdc;
}

a {
  transition: .4s;
}

.sec-privacy {
  padding-bottom: 138px;
}

@media screen and (max-width: 834px) {
  .sec-privacy {
    padding-bottom: 25px;
  }
}

.txt-privacy {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 2.15;
  font-family: "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  padding: 34px 10px;
}

@media screen and (max-width: 834px) {
  .txt-privacy {
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 1.71;
    padding: 0 4px;
  }
}

.sec-tokusho {
  padding-bottom: 137px;
}

@media screen and (max-width: 834px) {
  .sec-tokusho {
    padding-bottom: 5px;
  }
}

.list-tokusho {
  padding: 79px 10px;
}

@media screen and (max-width: 834px) {
  .list-tokusho {
    padding: 0;
  }
}

.list-tokusho dl {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 26px 0 20px;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 834px) {
  .list-tokusho dl {
    padding: 0 0 10px;
    margin-bottom: 9px;
  }
  .list-tokusho dl:last-child {
    border-bottom: none;
  }
}

.list-tokusho dt, .list-tokusho dd {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.71;
}

@media screen and (max-width: 834px) {
  .list-tokusho dt, .list-tokusho dd {
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 1.82;
  }
}

.list-tokusho dt {
  font-weight: bold;
  width: 191px;
  padding: 0 21px;
}

@media screen and (max-width: 834px) {
  .list-tokusho dt {
    width: 100%;
    padding: 0;
  }
}

.list-tokusho dd {
  width: calc(100% - 191px);
}

@media screen and (max-width: 834px) {
  .list-tokusho dd {
    width: 100%;
  }
}

.list-tokusho dd a {
  color: inherit;
}

.list-tokusho dd a:hover {
  text-decoration: underline;
}

.sec-gift {
  position: relative;
  /*margin-top: -15.5%;*/
  margin-top: -170px;
}

@media screen and (max-width: 1299px) {
  .sec-gift {
    margin-top: 50px;
  }
}

@media screen and (max-width: 834px) {
  .sec-gift {
    margin-top: 20px;
  }
}

.gift {
  position: relative;
  color: #fff;
}

.gift img {
  display: block;
  width: 100%;
}

.gift__ttl {
  font-size: 62.5px;
  font-size: 6.25rem;
  text-align: center;
  line-height: .7;
  padding-bottom: 23px;
  border-bottom: solid 1px #fff;
  margin-bottom: 24px;
}

.gift__ttl span {
  font-size: 40px;
  font-size: 4rem;
}

.gift__ttl small {
  display: block;
  margin-top: 30px;
  font-weight: 300;
  font-size: 27.5px;
  font-size: 2.75rem;
}

@media screen and (max-width: 990px) {
  .gift__ttl {
    line-height: 1;
  }
}

@media screen and (max-width: 640px) {
  .gift__ttl {
    font-size: 30px;
    font-size: 3rem;
    line-height: .8;
    padding-bottom: 10px;
    margin-bottom: 7px;
  }
  .gift__ttl span {
    font-size: 18px;
    font-size: 1.8rem;
  }
  .gift__ttl small {
    font-size: 11px;
    font-size: 1.1rem;
    margin-top: 17px;
  }
}

.gift p {
  text-align: center;
  font-size: 28px;
  font-size: 2.8rem;
}

@media screen and (max-width: 640px) {
  .gift p {
    font-size: 9px;
    font-size: 0.9rem;
  }
}

.gift__content {
  width: 54.5%;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 49%;
  transform: translateY(-50%);
  padding-right: 80px;
}

@media screen and (max-width: 990px) {
  .gift__content {
    width: 65%;
  }
}

@media screen and (max-width: 834px) {
  .gift__content {
    width: 100%;
    padding: 0 60px;
    top: 50%;
  }
}

.gift-story {
  padding-top: 100px;
  padding-bottom: 60px;
  margin-top: -60px;
  background: url(../img/feature/feather/bg-01.png) no-repeat top left -20px;
}

.gift-story .txt-basic {
  text-align: center;
  line-height: 2.1875;
  max-width: 750px;
  margin: 0 auto;
}

@media screen and (min-width: 835px) {
  .gift-story .txt-basic {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 834px) {
  .gift-story .txt-basic {
    line-height: 1.81;
  }
}

.gift-story .breadcrumb {
  margin-bottom: 36px;
}

@media screen and (max-width: 834px) {
  .gift-story {
    background: url(../img/feature/feather/bg-01-sp.png) no-repeat top 5px left 22px;
    padding-top: 27px;
    padding-bottom: 26px;
    margin-top: 0;
  }
}

.ttl-comp05 {
  text-align: center;
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 1.44;
  margin-bottom: 28px;
}

@media screen and (max-width: 834px) {
  .ttl-comp05 {
    font-size: 18px;
    font-size: 1.8rem;
    margin-bottom: 14px;
  }
}

.gift-handle {
  padding-top: 50px;
  margin-bottom: 80px;
  border-top: solid 1px #c2c3c3;
}

@media screen and (max-width: 834px) {
  .gift-handle {
    overflow: hidden;
  }
}

.gift-handle img {
  display: block;
  margin: 0 auto 30px;
}

.gift-handle .txt-basic {
  max-width: 825px;
  margin: -109px auto 0;
  padding-right: 22px;
  float: right;
  text-align: left;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 834px) {
  .gift-handle .txt-basic {
    font-size: 11px;
    font-size: 1.1rem;
    margin-top: -35px;
    padding-right: 8px;
  }
}

@media screen and (max-width: 834px) {
  .gift-handle {
    padding-top: 26px;
    margin-bottom: 26px;
  }
}

.img-scroll {
  /*
	height: 150px;
	background: url("../img/feature/feather/img-05.jpg") repeat-x center;
	background-size: auto 100%;
	@include mq{
		margin-bottom: 27px;
		height: 60px;
		background-image: url("../img/feature/feather/img-05-sp.jpg");
	}*/
  margin-bottom: 60px;
}

@media screen and (max-width: 834px) {
  .img-scroll {
    margin-bottom: 27px;
  }
}

.img-scroll .item {
  width: 150px;
}

@media screen and (max-width: 834px) {
  .img-scroll .item {
    width: 75px;
  }
}

.sec-story {
  background: url(../img/feature/feather/bg-01.png) no-repeat top 7% right -20px;
  margin-bottom: 86px;
}

@media screen and (max-width: 834px) {
  .sec-story {
    margin-bottom: 25px;
  }
}

.sec-story .txt-des {
  max-width: 825px;
  margin: 0 auto 70px;
  line-height: 2.125;
  text-align: center;
}

@media screen and (min-width: 835px) {
  .sec-story .txt-des {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 834px) {
  .sec-story .txt-des {
    line-height: 20px;
    margin-bottom: 30px;
  }
}

.sec-story .ttl-comp05 {
  margin-bottom: 38px;
}

@media screen and (max-width: 834px) {
  .sec-story .ttl-comp05 {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 834px) {
  .sec-story {
    background: url(../img/feature/feather/bg-01-sp.png) no-repeat top -3px right -15px;
  }
}

.list-step__ttl {
  text-align: center;
  font-size: 30px;
  font-size: 3rem;
  margin-bottom: 17px;
}

@media screen and (max-width: 834px) {
  .list-step__ttl {
    font-size: 18px;
    font-size: 1.8rem;
    margin-bottom: 5px;
  }
}

.list-step .txt-basic {
  padding-bottom: 17px;
  border-bottom: solid 1px #b0b0b0;
  margin-bottom: 30px;
  text-align: center;
}

@media screen and (max-width: 834px) {
  .list-step .txt-basic {
    line-height: 1.81;
    padding-bottom: 13px;
    margin-bottom: 14px;
  }
}

.list-step .btn-click img {
  max-width: 53px;
}

@media screen and (max-width: 834px) {
  .list-step .btn-click img {
    max-width: 45px;
  }
}

.list-step .btn-click:hover {
  opacity: .7;
}

@media screen and (min-width: 835px) {
  .list-step__img img {
    min-height: 350px;
    object-fit: cover;
    font-family: 'object-fit: cover';
  }
}

@media screen and (max-width: 834px) {
  .list-step__img {
    order: 1;
  }
}

.list-step__content {
  background: url(../img/feature/feather/bg-02.jpg) repeat;
  background-size: auto 100%;
  padding: 0 45px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 834px) {
  .list-step__content {
    order: 2;
    padding: 20px 20px;
  }
}

.list-step__item {
  display: flex;
  flex-wrap: wrap;
}

.list-step__item > * {
  width: 50%;
}

@media screen and (max-width: 834px) {
  .list-step__item > * {
    width: 100%;
  }
}

@media screen and (min-width: 835px) {
  .list-step__item:nth-child(2n) .list-step__content {
    order: 2;
  }
  .list-step__item:nth-child(2n) .list-step__content {
    order: 1;
  }
}

.box-menu {
  margin-top: -80px;
}

@media screen and (max-width: 834px) {
  .box-menu {
    margin-top: 0;
  }
}

@media screen and (min-width: 835px) {
  .box-menu .img-menu {
    right: -120px;
    position: relative;
  }
}

@media screen and (max-width: 1200px) {
  .box-menu .img-menu {
    right: -50px;
    bottom: -50px;
  }
}

@media screen and (max-width: 834px) {
  .box-menu .img-menu {
    bottom: auto;
    right: auto;
  }
}

.box-menu .txt-right img {
  max-width: 440px;
}

@media screen and (max-width: 834px) {
  .box-menu .txt-right {
    text-align: center !important;
  }
  .box-menu .txt-right img {
    max-width: 275px;
    margin-bottom: 24px;
    position: relative;
    z-index: 2;
  }
}

.box-menu .order__list {
  margin-top: -63px;
}

.gift-handle__img {
  position: relative;
  max-width: 820px;
  margin: 0 auto;
}

@media screen and (min-width: 835px) {
  .gift-handle__img .txt-basic {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 325px;
    text-align: left;
    margin: 53px 0 0;
    padding-right: 18px;
  }
}

@media screen and (max-width: 834px) {
  .gift-handle__img .txt-basic {
    text-align: center;
    margin-bottom: 29px;
  }
}

.gift-handle__img img {
  margin-bottom: 27px;
}

.list-step--style02 .list-step__txt {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 43px;
  border-bottom: solid 1px #b0b0b0;
  padding-bottom: 32px;
  margin-bottom: 32px;
}

@media screen and (max-width: 834px) {
  .list-step--style02 .list-step__txt {
    padding-right: 25px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    justify-content: center;
  }
}

@media screen and (max-width: 374px) {
  .list-step--style02 .list-step__txt {
    justify-content: flex-end;
  }
}

.list-step--style02 .list-step__ttl {
  margin-left: 55px;
  text-align: left;
  margin-bottom: 0;
  width: 70px;
}

@media screen and (max-width: 834px) {
  .list-step--style02 .list-step__ttl {
    font-size: 20px;
    font-size: 2rem;
    margin-left: 0;
  }
}

.list-step--style02 .txt-basic {
  height: 180px;
  line-height: 1.9;
  padding-bottom: 0;
  border-bottom: 0;
  margin-bottom: 0;
  text-align: left;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .list-step--style02 .txt-basic {
    width: calc(100% - 70px);
  }
}

@media screen and (max-width: 834px) {
  .list-step--style02 .txt-basic {
    height: 135px;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 834px) {
  .list-step--style02 .txt-basic {
    width: 170px;
  }
}

.sec-gift--style02 .gift-story {
  padding-bottom: 70px;
}

@media screen and (max-width: 834px) {
  .sec-gift--style02 .gift-story {
    padding-top: 28px;
    padding-bottom: 25px;
  }
}

.sec-gift--style02 .gift-story .breadcrumb {
  margin-bottom: 45px;
}

.sec-gift--style02 .gift-handle {
  padding-top: 62px;
  padding-bottom: 0;
  border-bottom: solid 1px #c3c1c4;
  margin-bottom: 54px;
}

@media screen and (max-width: 834px) {
  .sec-gift--style02 .gift-handle {
    padding-top: 26px;
    margin-bottom: 28px;
  }
  .sec-gift--style02 .gift-handle img {
    margin-bottom: 33px;
  }
}

.sec-gift--style02 .ttl-com06 {
  margin-bottom: 30px;
}

.sec-story--style02 .ttl-com06 {
  margin-bottom: 55px;
}

@media screen and (max-width: 834px) {
  .sec-story--style02 .ttl-com06 {
    margin-bottom: 30px;
  }
}

.mt0 {
  margin-top: 0;
}

.sec-menu--style02 .ttl-com06 {
  margin-bottom: 55px;
}

@media screen and (max-width: 834px) {
  .sec-menu--style02 .ttl-com06 {
    margin-bottom: 30px;
  }
}

.ip6 {
  display: none;
}

@media (max-width: 375px) {
  .ip6 {
    display: initial;
  }
}

@media screen and (max-width: 330px) {
  .ip6 {
    display: none;
  }
}

.sec-howto {
  padding-bottom: 125px;
  background: url("../img/howto/mark1.png") no-repeat right top 860px, url("../img/howto/mark2.png") no-repeat left bottom;
  background-size: 329px auto;
}

@media screen and (max-width: 834px) {
  .sec-howto {
    background: none;
    padding-bottom: 40px;
  }
}

.sec-howto .ttl-comp01 {
  margin-bottom: 60px;
}

@media screen and (max-width: 834px) {
  .sec-howto .ttl-comp01 {
    margin-bottom: 30px;
  }
}

.sec-howto .lead {
  text-align: center;
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.8;
  margin-bottom: 70px;
}

@media screen and (max-width: 834px) {
  .sec-howto .lead {
    font-size: 12px;
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
}

.sec-howto .howto-item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 85px;
}

@media screen and (max-width: 834px) {
  .sec-howto .howto-item {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 835px) {
  .sec-howto .howto-item__content {
    flex: 1;
  }
}

.sec-howto .howto-item__ttl {
  width: 180px;
  padding-right: 65px;
}

@media screen and (max-width: 834px) {
  .sec-howto .howto-item__ttl {
    width: 100%;
    padding: 30px 0 0;
    border-top: 1px solid #b5b5b5;
    margin-bottom: 25px;
  }
}

.sec-howto .howto-item__ttl strong {
  display: block;
  font-size: 38px;
  font-size: 3.8rem;
  border-right: 1px solid #b5b5b5;
  padding: 10px 20px 0 0;
  margin-right: 20px;
}

@media screen and (max-width: 834px) {
  .sec-howto .howto-item__ttl strong {
    border: none;
    font-size: 14px;
    font-size: 1.4rem;
    display: inline-block;
    padding: 0;
    margin-right: 15px;
  }
}

.sec-howto .howto-item__ttl small {
  font-size: 15px;
  font-size: 1.5rem;
  padding-top: 15px;
}

.sec-howto .howto-item__img {
  margin-bottom: 53px;
}

@media screen and (max-width: 834px) {
  .sec-howto .howto-item__img {
    margin-bottom: 25px;
  }
}

.sec-howto .howto-item__list li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

@media screen and (max-width: 834px) {
  .sec-howto .howto-item__list li {
    margin-bottom: 20px;
  }
}

.sec-howto .howto-item__list li:last-of-type {
  margin-bottom: 0;
}

.sec-howto .howto-item__list li .num {
  width: 75px;
}

@media screen and (max-width: 834px) {
  .sec-howto .howto-item__list li .num {
    width: 45px;
  }
}

.sec-howto .howto-item__list li .txt {
  padding-left: 55px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.9;
}

@media screen and (min-width: 835px) {
  .sec-howto .howto-item__list li .txt {
    flex: 1;
  }
}

@media screen and (max-width: 834px) {
  .sec-howto .howto-item__list li .txt {
    width: calc(100% - 45px);
    padding-left: 20px;
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.sec-howto .howto-item__list--style02 {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.sec-howto .howto-item__list--style02 li {
  width: 46%;
}

@media screen and (max-width: 990px) {
  .sec-howto .howto-item__list--style02 li {
    width: 100%;
  }
}

.sec-howto .point-block {
  position: relative;
  border: 1px solid #c0c0c1;
  padding: 70px 45px 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 834px) {
  .sec-howto .point-block {
    padding: 40px 25px 5px;
    margin-top: 60px;
  }
}

.sec-howto .point-block__ttl {
  font-size: 22px;
  font-size: 2.2rem;
  display: inline-block;
  padding: 0 25px;
  height: 50px;
  line-height: 50px;
  border-radius: 7px;
  background: #313131;
  color: #FFF;
  text-align: center;
  position: absolute;
  top: -25px;
  left: 45px;
}

@media screen and (max-width: 834px) {
  .sec-howto .point-block__ttl {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 35px;
    height: 35px;
    margin: auto;
    left: 0;
    right: 0;
    top: -18px;
    width: 200px;
    padding: 0;
  }
}

.sec-howto .point-block__txt {
  width: 48%;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.8;
  margin-bottom: 35px;
}

@media screen and (max-width: 834px) {
  .sec-howto .point-block__txt {
    width: 100%;
    font-size: 11px;
    font-size: 1.1rem;
    margin-bottom: 20px;
  }
}

.ouchi-intro {
  margin-bottom: 65px;
}

@media screen and (max-width: 834px) {
  .ouchi-intro {
    margin-bottom: 30px;
  }
}

.ouchi-intro .intro-block {
  padding: 65px 0 20px;
  border-top: 1px solid #bdc3c2;
  border-bottom: 1px solid #bdc3c2;
}

@media screen and (max-width: 834px) {
  .ouchi-intro .intro-block {
    padding: 30px 0 0;
  }
}

.ouchi-intro .intro-item {
  position: relative;
  padding-left: 265px;
  padding-top: 20px;
  margin-bottom: 65px;
}

@media screen and (max-width: 834px) {
  .ouchi-intro .intro-item {
    padding: 0;
    max-width: 375px;
    margin: 0 auto 30px;
  }
}

.ouchi-intro .intro-item__ttl {
  position: absolute;
  left: -25px;
  top: 0;
  width: 265px;
}

@media screen and (max-width: 834px) {
  .ouchi-intro .intro-item__ttl {
    position: static;
    left: inherit;
    width: 100px;
    min-height: 300px;
  }
}

.ouchi-intro .intro-item__ttl .ttl--01 {
  width: 312px;
}

@media screen and (max-width: 834px) {
  .ouchi-intro .intro-item__ttl .ttl--01 {
    width: 100%;
  }
}

.ouchi-intro .intro-item__ttl .ttl--02 {
  margin: 20px auto 0;
  padding-left: 40px;
  font-size: 34px;
  font-size: 3.4rem;
}

@media screen and (max-width: 834px) {
  .ouchi-intro .intro-item__ttl .ttl--02 {
    font-size: 15px;
    font-size: 1.5rem;
    padding: 0;
    margin-top: 12px;
  }
}

.ouchi-intro .intro-item__img {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 43px;
}

@media screen and (max-width: 834px) {
  .ouchi-intro .intro-item__img {
    margin-bottom: -25px;
  }
}

.ouchi-intro .intro-item__img .img {
  width: calc( calc(100% - 5px) / 2);
}

@media screen and (max-width: 834px) {
  .ouchi-intro .intro-item__img .img {
    width: calc( calc(100% - 3px) / 2);
  }
}

.ouchi-intro .intro-item__img .img:nth-of-type(1) {
  width: 100%;
  margin-bottom: 5px;
}

@media screen and (max-width: 834px) {
  .ouchi-intro .intro-item__img .img:nth-of-type(1) {
    margin-bottom: 3px;
  }
}

.ouchi-intro .intro-item__img .img img {
  width: 100%;
  height: 282px;
  object-fit: cover;
  font-family: "object-fit:cover;";
}

@media screen and (max-width: 834px) {
  .ouchi-intro .intro-item__img .img img {
    height: 107px;
  }
}

.ouchi-intro .intro-item__img--style02 .left-col,
.ouchi-intro .intro-item__img--style02 .right-col {
  width: calc( calc(100% - 5px) / 2);
}

@media screen and (max-width: 834px) {
  .ouchi-intro .intro-item__img--style02 .left-col,
  .ouchi-intro .intro-item__img--style02 .right-col {
    width: calc( calc(100% - 3px) / 2);
  }
}

.ouchi-intro .intro-item__img--style02 .left-col {
  margin-right: 5px;
}

@media screen and (max-width: 834px) {
  .ouchi-intro .intro-item__img--style02 .left-col {
    margin-right: 3px;
  }
}

.ouchi-intro .intro-item__img--style02 .left-col .img:nth-of-type(1) img {
  height: 570px;
}

@media screen and (max-width: 834px) {
  .ouchi-intro .intro-item__img--style02 .left-col .img:nth-of-type(1) img {
    height: 217px;
  }
}

.ouchi-intro .intro-item__img--style02 .right-col .img {
  width: 100%;
  margin-right: 0;
}

@media screen and (min-width: 835px) {
  .ouchi-intro .intro-item__content {
    position: relative;
    padding-left: 45%;
  }
}

.ouchi-intro .intro-item__content .img {
  position: absolute;
  left: 0;
  width: 48%;
  z-index: 2;
}

@media screen and (max-width: 834px) {
  .ouchi-intro .intro-item__content .img {
    left: inherit;
    width: 65%;
    max-width: 220px;
  }
}

.ouchi-intro .intro-item__content .txt-inner {
  width: 100%;
  position: relative;
  border-left: 1px solid #888687;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 300px;
  padding-bottom: 22px;
}

@media screen and (max-width: 834px) {
  .ouchi-intro .intro-item__content .txt-inner {
    border: none;
    min-height: inherit;
    padding: 0;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

.ouchi-intro .intro-item__content .txt-inner .ttl-col {
  width: 125px;
}

@media screen and (max-width: 834px) {
  .ouchi-intro .intro-item__content .txt-inner .ttl-col {
    order: 2;
    width: 50px;
  }
}

.ouchi-intro .intro-item__content .txt-inner .txt-col {
  width: calc(100% - 125px);
}

@media screen and (max-width: 834px) {
  .ouchi-intro .intro-item__content .txt-inner .txt-col {
    order: 1;
    width: calc(100% - 50px);
    padding-right: 20px;
    border-right: 1px solid #888687;
    padding-bottom: 10px;
  }
}

.ouchi-intro .intro-item__content .txt-inner .ttl {
  font-size: 52px;
  font-size: 5.2rem;
  margin: 0 auto;
  padding-top: 5px;
}

@media screen and (max-width: 834px) {
  .ouchi-intro .intro-item__content .txt-inner .ttl {
    font-size: 24px;
    font-size: 2.4rem;
  }
}

.ouchi-intro .intro-item__content .txt-inner .txt {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2.17;
  margin-bottom: 40px;
}

@media screen and (max-width: 834px) {
  .ouchi-intro .intro-item__content .txt-inner .txt {
    margin-bottom: 0;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.ouchi-intro .intro-item__content .btn {
  width: 100%;
  border-radius: 4px;
}

@media screen and (max-width: 834px) {
  .ouchi-intro .intro-item__content .btn {
    border-radius: 0;
    height: 45px;
  }
}

.ouchi-intro .intro-item--01 .intro-item__content .img {
  top: -85px;
}

@media screen and (max-width: 834px) {
  .ouchi-intro .intro-item--01 .intro-item__content .img {
    right: 5px;
    top: 235px;
    max-width: 200px;
  }
}

.ouchi-intro .intro-item--02 {
  padding-left: 0;
  padding-right: 265px;
}

@media screen and (max-width: 834px) {
  .ouchi-intro .intro-item--02 {
    padding-right: 0;
  }
}

.ouchi-intro .intro-item--02 .intro-item__ttl {
  left: inherit;
  right: 0;
}

.ouchi-intro .intro-item--02 .intro-item__ttl .ttl--01,
.ouchi-intro .intro-item--02 .intro-item__ttl .ttl--02 {
  position: relative;
  left: -25px;
  z-index: 2;
}

@media screen and (max-width: 834px) {
  .ouchi-intro .intro-item--02 .intro-item__ttl .ttl--01,
  .ouchi-intro .intro-item--02 .intro-item__ttl .ttl--02 {
    position: static;
    left: inherit;
  }
}

.ouchi-intro .intro-item--02 .intro-item__content .img {
  top: 35px;
}

@media screen and (max-width: 834px) {
  .ouchi-intro .intro-item--02 .intro-item__content .img {
    right: -20px;
    top: 260px;
  }
}

.ouchi-intro .intro-item--03 .intro-item__content .img {
  top: 5px;
}

@media screen and (max-width: 834px) {
  .ouchi-intro .intro-item--03 .intro-item__content .img {
    right: -20px;
    top: 250px;
  }
}
