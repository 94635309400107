.main-visual{
	margin-bottom: 30px;
	height: 320px;
	overflow: hidden;
	@include mq {
		margin-bottom: 28px;
		height: 120px;
	}
	img{
		@include mq(md-min){
			width: 100%;
			min-height: 100%;
			font-family:'object-fit: cover;';
			object-fit: cover;
			object-position: center; 
		}
		@include mq {
			width: 100%;
			min-height: 100%;
			font-family:'object-fit: cover;';
			object-fit: cover;
			object-position: center; 
		}
	}
}
.nav01{
	display: flex;
	justify-content: flex-end;
	padding-right: 7px;
	margin: 0 -13px 66px;
	@include mq{
		justify-content: center;
		margin: 0 -11px 32px;
	}
	a,span{
		@include font-size(14);
		color:inherit;
		
		padding: 0 13px;
		position: relative;
			&:after{
				content:"";
				height: 15px;
				width: 1px;
				background: #000000;
				
				position: absolute;
				right: 0;
				top: 50%;
				
				transform: translateY(-50%);
				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					top: 42%;
				}
			};
		@include mq{
			@include font-size(11);
			padding: 0 11px;
			&:after{
				height: 11px;
			}
		}
	}
	span{
		color: #880101;
	}
	li{
		&:last-child{
			a,span{
				&:after{
					display: none;
				}
			}
		}
	
	}
}
.line-up__list{
	display: flex;
	&--style02{
		margin-bottom: -48px;
		margin-left: -25px;
		margin-right: -25px;
		flex-wrap: wrap;
		@include mq{
			display: flex;
			margin-left: -12px;
			margin-right: -12px;
		}
		a{
			color:inherit;
		}
		.item{
			width: 33.33%;
			padding: 0 25px;
			margin-right: 0;
			margin-bottom: 48px;
			@include mq{
				width: 50%;
				margin-right: 0;
				padding: 0 12px;
				margin-bottom: 17px;
			}
		}
		.item__price{
			@include font-size(16);
			text-align: right;
			@include mq{
				@include font-size(11);
				text-align: left;
			}
		}
		.item__img{
			margin-bottom: 52px;
			padding-left: 20px;
			@include mq{
				padding-left: 0;
				margin-bottom: 15px;
			}
		}
		.item__ttl{
			margin-bottom: 8px;
			@include font-size(16);
			@include mq{
				padding-left: 0;
				@include font-size(11);
				margin-bottom: 4px;
			}
			&--underline{
				a{
					text-decoration: underline;
				}
				&:hover{
					text-decoration: none!important;
					a{
						text-decoration: none!important;
					}
				}
			}
		}
	}
}
.sec-category{
	padding-bottom: 130px;
	@include mq{
		padding-bottom: 84px;
	}
}
.pagination{
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 595px;
	margin: 100px auto 50px;
	@include mq(){
		padding: 0 15px;
		margin-top: 65px;
		margin-bottom: 27px;
	}
	li{
		width: 33.33%;
		&.current-page{
			@include font-size(14);
			text-align: center;
			margin-left: 15px;
			margin-top: -5px;
			span{
				@include font-size(20);
			}
			@include mq(){
				display: none;
			}
		}
		&.next-page{
			text-align: right;
			a, .icon_prev_dis, .icon_next_dis{
				&:before{
					transform: rotate(45deg);
					right: -15px;
					left: auto;
					@include mq(){
						right: -10px;
					}
				}
			}
		}
		a, .icon_prev_dis, .icon_next_dis{
			@include font-size(14);
			color: #000;
			position: relative;
			@include mq(){
				@include font-size(11);
			}
			&:before{
				content: '';
				display: block;
				width: 9px;
				height: 9px;
				border-right: 1px solid #000;
				border-top: 1px solid #000;
				position: absolute;
				top: 4px;
				left: -15px;
				transform: rotate(-135deg);
				@include mq(){
					width: 6px;
					height: 6px;
					left: -10px;
				}
			}
		}
		.icon_prev_dis,
		.icon_next_dis{
			opacity: 0.5;
		}
	}
}