body {
	color: $color-text-black;
	font-family: $font-mincho;
	font-weight: normal;
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	line-height: 1.4;
	background: url("../img/common/bg.jpg");
	background-size: contain;
	background-position: center top;
	overflow-x: hidden;
	&.Android{
		@include mq {
			font-family: 'Noto Serif JP', serif;
		}
	}
}

.backtop {
	right: 0;
	position: fixed;
	top: 50%;
	transform: translateY(-50%);
	width: 50px;
	height: 50px;
	@include smooth-transition;
	display: none;
}

//header
.header {
	z-index: 100;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding: 22px 30px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	@include mq(xl) {
		padding: 22px 20px;
	}
	@include mq {
		padding: 10px;
	}
	&__left {
		text-align: left;
		flex-shrink: 0;
	}
	&__logo {
		display: block;
		max-width: 106px;
		@include mq(lg) {
			max-width: 80px;
		}
		@include mq {
			max-width: 39px;
		}
	}
	&__right {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		@include mq {
			align-items: flex-start;
			padding: 0;
			margin-top: -2px;
		}
	}
	&__btn {
		margin: 13px 0 13px 47px;
		display: flex;
		@include mq(xl) {
			margin: 13px 0 13px 15px;
		}
		@include mq {
			margin: 0;
		}
		&:hover{
			text-decoration: none;
		}
		a{
			&:hover{
				text-decoration: none;
			}
		}
		.ico {
			width: 66px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgba(255,255,255,0.37);
			border: 1px solid rgba(255,255,255,0.37);
			border-right: none;
			@include mq(lg) {
				width: 52px;
			}
			@include mq {
				border: none;
				background-color: transparent;
				margin-right: 11px;
				margin-top: -1px;
				width: 22px;
				height: 22px;
			}
			img {
				max-width: 29px;
				@include mq(lg) {
					max-width: 22px;
				}
			}
		}
	}
	.nav-menu{
		@include mq {
			display: none;
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			z-index: 99;
			padding: 0;
			background-color: #313131;
		}
		.show-menu{
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;
			margin-top: 7px;
			@include mq {
				display: block;
				margin-top: 25.4%;
			}
			>.item{
				padding: 11px 25px;
				position: relative;
				@include smooth-transition;
				@include mq(xl) {
					padding: 11px 15px;
				}
				@include mq(lg) {
					padding: 11px 12px;
				}
				@include mq {
					margin: 15px 0 0;
					padding: 0;
				}
				>a{
					display: block;
					@include font-size(16);
					color: #ffffff;
					padding: 0 0;
					letter-spacing: 0.1em;
					@include mq(lg) {
						@include font-size(14);
					}
					@include mq {
						text-align: center;
						position: relative;
						letter-spacing: 0;
					}
					&:focus{
						outline: none;
					}
					&:hover{
						text-decoration: none;
					}
				}
				.sub-menu{
					position: absolute;
					top: 100%;
					left: -20px;
					right: -20px;
					background: rgba(0, 0, 0, 0.9);
					z-index: 1;
					@include mq(md-min) {
						display: none !important;
						-webkit-backface-visibility: hidden;
						-webkit-transition: all .3s ease;
						-o-transition: all .3s ease;
						transition: all .3s ease;
					}
					@include mq {
						/*display: none;*/
						position: static;
						background: none;
						border-bottom: none;
						text-align: center;
					}
					li{
						padding: 0 10px;
						@include mq {
							padding: 0;
							margin-top: 15px;
							
						}
						a{
							display: block;
							color: white;
							@include font-size(14);
							border-bottom: 1px solid rgba(255,255,255,0.5);
							text-align: center;
							padding: 5px 0;
							&:hover{
								@include mq(md-min) {
								text-decoration: none;
								opacity: 0.6;
								}
							}
							@include mq {
								border-bottom: none;
								padding: 0;
							}
						}
						&:last-child{
							a{
								border-bottom: none;
							}
						}
					}
				}
				&:hover{
					>a{
					@include mq(md-min) {
						&:hover {
								opacity: 0.6;
							}
						}
					}
					.sub-menu{
						@include mq (md-min){
							display: block !important;
						}
					}
				}
			}
		}

	}
	.mobile-icon {
		width: 15px;
		height: 17px;
		cursor: pointer;
		z-index: 5;
		display: none;
		position: relative;
		@include mq {
			display: block;
		}
		span{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%);
			display: block;
			width: 100%;
			height: 2px;
			background-color: #ffffff;
			font-size: 0;
			user-select: none;
			transition: background-color 0.3s;
			&:before,&:after{
				content: '';
				position: absolute;
				left: 0;
				width: 100%;
				height: 100%;
				background: #ffffff;
				transition: transform 0.3s;
			}
			&:before{
				transform: translateY(-250%);
				top: -2px;
			}
			&:after{
				transform: translateY(250%);
				bottom: -2px;
			}
		}
	}
	.close-menu {
		width: 22px;
		height: 22px;
		transition: background .5s;
		position: fixed;
		right: 20px;
		top: 30px;
		transform: translateY(-50%);
		cursor: pointer;
		z-index: 100;
		display: none;
		@include mq {
			display: block;
		}
		span {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%);
			display: block;
			width: 100%;
			height: 3px;
			font-size: 0;
			user-select: none;
			&:before,&:after{
				content: '';
				position: absolute;
				left: 0;
				width: 100%;
				height: 100%;
				background: #ffffff;
				transition: transform 0.3s;
				border-radius: 3px;
			}
			&:before {
				transform: translateY(0) rotate(45deg);
				top: 2px;
			}
			&:after {
				transform: translateY(0) rotate(-45deg);
				bottom: -2px;
			}
		}
	}
	//&--top {
	//	@include mq {
	//		padding: 40px 15%;
	//		.header__logo {
	//			max-width: 50px;
	//		}
	//		.header__btn .ico {
	//			width: 26px;
	//			height: 26px;
	//			margin-right: 10px;
	//			margin-top: 0;
	//			img {
	//				width: 100%;
	//				max-width: 100%;
	//			}
	//		}
	//		.header__right {
	//			padding: 0 8px 0 0;
	//		}
	//		.mobile-icon {
	//			width: 20px;
	//			height: 28px;
	//			span {
	//				height: 1px;
	//				&:before {
	//					top: -3px;
	//				}
	//			}
	//		}
	//	}
	//}
}

//footer
.footer {
	background-color: #313131;
	padding: 69px 0 28px;
	@include mq {
		padding: 30px 0 4px;
	}
	&__top {
		border-bottom: 1px solid rgba(255,255,255,0.25);
		padding-bottom: 54px;
		@include mq {
			padding-bottom: 31px;
		}
	}
	&__row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -51px;
		@include mq(lg) {
			margin: 0 -25px;
		}
		@include mq {
			margin: 0 -10px;
		}
		@include mq(sm) {
			margin: 0;
			&:first-child .footer__col {
				margin-bottom: 10px;
				&:first-child {
					order: 1;
					margin-bottom: 28px;
				}
			}
		}
	}
	&__col {
		width: 50%;
		padding: 0 49px;
		@include mq(lg) {
			padding: 0 25px;
		}
		@include mq {
			padding: 0 10px;
		}
		@include mq(sm) {
			width: 100%;
			padding: 0;
		}
	}
	&__list {
		margin: 20px 0 40px;
		@include mq {
			margin: 20px 0;
		}
		@include mq(sm) {
			margin: 0;
		}
		> li {
			color: #ffffff;
			margin-bottom: 28px;
			@include mq {
				margin-bottom: 15px;
			}
			.ttl {
				@include font-size(20);
				line-height: 1.5;
				letter-spacing: 0.05em;
				margin-bottom: 2px;
				@include mq {
					@include font-size(11);
					line-height: 1.43;
					margin-bottom: 0;
				}
			}
			.txt {
				@include font-size(14);
				line-height: 2.15;
				@include mq {
					@include font-size(11);
					line-height: 1.43;
				}
			}
		}
	}
	&__payment {
		text-align: center;
		@include mq(sm) {
			border-radius: 7px;
			overflow: hidden;
			margin-top: 12px;
		}
	}
	&__middle {
		padding: 46px 0 48px;
		border-bottom: 1px solid rgba(255,255,255,0.25);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		@include mq {
			padding: 31px 0 17px;
		}
	}
	&__logo {
		max-width: 124px;
		margin-bottom: 30px;
		flex-shrink: 0;
		@include mq {
			max-width: 100px;
			margin-bottom: 26px;
		}
	}
	&__menu {
		display: flex;
		flex-wrap: wrap;
		> li {
			@include mq(sm) {
				width: 100%;
				text-align: center;
				margin-bottom: 6px;
			}
			&:not(:first-child) {
				margin-left: 25px;
				padding-left: 28px;
				position: relative;
				@include mq {
					margin-left: 15px;
					padding-left: 15px;
				}
				@include mq(sm) {
					margin-left: 0;
					padding-left: 0;
				}
				&:before {
					content: '';
					position: absolute;
					z-index: 1;
					left: 0;
					top: 8px;
					width: 1px;
					height: 16px;
					background-color: #fff;
					@include mq {
						height: 10px;
						top: 7px;
					}
					@include mq(sm) {
						display: none;
					}
				}
			}
			> a {
				display: inline-block;
				color: #ffffff;
				@include font-size(16);
				line-height: 2.25;
				letter-spacing: 0.1em;
				@include mq {
					letter-spacing: 0;
					@include font-size(11);
				}
				@include mq(sm) {
					text-decoration: underline;
				}
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	&__bottom {
		text-align: center;
		@include font-size(14);
		line-height: 1.79;
		letter-spacing: 0.05em;
		color: #ffffff;
		padding: 20px 0;
		@include mq {
			@include font-size(11);
			padding: 15px 0;
		}
	}
}

.breadcrumb{
	@include font-size(13);
	color:#000;
	
	margin-bottom: 62px;
	.inner{
		display: flex;
		justify-content: flex-end;
		padding-right: 30px;
	}
	a{
		color:inherit;
	}
	span{
		margin-left: 14px;
	}
	i{
		margin-left: 10px;
	}
	@include mq{
		display: none;
	}
}