.inner {
	width: 100%;
	display: block;
	max-width: 1040px;
	padding: 0 20px;
	margin: 0 auto;
}

.clearfix {
	*zoom: 1;
	&:before {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
		content: "";
		display: table;
	}
}

a{
	text-decoration: none;
	@include smooth-transition;
	&[href^="tel"] {
		@include mq(md-min){
			pointer-events: none;
		}
	}
}

.pc{
	@include mq(){
		display: none !important;
	}
	&-ipd{
		@include mq(ipd){
			display: none !important;
		}
	}
	&-lg{
		@include mq(lg){
			display: none !important;
		}
	}
	&-sm{
		@include mq(sm){
			display: none !important;
		}
	}
	&-xs{
		@include mq(xs){
			display: none !important;
		}
	}
}
.sp{
	@include mq(md-min){
		display: none !important;
	}
	&-ipd{
		@include mq(ipd-min){
			display: none !important;
		}
	}
	&-lg{
		@include mq(lg-min){
			display: none !important;
		}
	}
	&-sm{
		@include mq(sm-min){
			display: none !important;
		}
	}
	&-xs {
		@include mq(xs-min) {
			display: none !important;
		}
	}
}

//ttl
.ttl-comp{
	&01{
			text-align: center;
			@include font-size(27);
			padding-bottom: 34px;
			margin-bottom: 27px;
			position: relative;
			&:after{
				content:"";
				position: absolute;
				left: 50%;
				bottom:0;
				transform: translateX(-50%);
				width: 45px;
				height: 2px;
				background: #7d0000;
			}
			@include mq{
				@include font-size(18);
				padding-bottom: 16px;
				
			}
			&--sm {
				@include mq(md-min) {
					@include font-size(26);
					padding-bottom: 32px;
					margin-bottom: 25px;
				}
			}
		}
	&02{

	}
	&03{

	}
	&04{
		@include font-size(25);
		display: flex;
		align-items: flex-end;
		margin-bottom: 37px;
		letter-spacing: 0.1em;
		span{
			@include font-size(14);
			letter-spacing: 0.1em;
			margin: 0 0 5px 17px;
		}
		@include mq{
			@include font-size(14);
			margin-bottom: 17px;
			span{
				margin: 0 0 1px 9px;
				@include font-size(10);
			}
		}
	}
}
.ttl-com06{
	text-align: center;
	@include font-size(36);
	padding-bottom: 24px;
	position: relative;
	&:after{
		content:"";
		position: absolute;
		width: 45px;
		height: 2px;
		bottom:0;
		left: 50%;
		transform: translateX(-50%);
		background: #7d0000;
	}
	@include mq{
		@include font-size(18);
		padding-bottom: 19px;
		margin-bottom: 10px;
	}
}
//text
.txt{
	&-basic{
		line-height: 1.8;
		@include font-size(14);
		@include mq(){
			@include font-size(11);
			line-height: 1.6;
		}
	}
	&-center {
		text-align: center;
	}
	&-right {
		text-align: right;
	}
	&-left {
		text-align: left;
	}
	&-vertical {
		@include text-vertical;
	}
}

//btn
.btn {
	width: 246px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid rgba(255,255,255,1);
	background-color: $color-red;
	color: #ffffff;
	font-weight: bold;
	@include font-size(18);
	letter-spacing: 0.1em;
	position: relative;
	border-radius: 0;
	padding: 0;
	@include smooth-transition;
	@include mq {
		@include font-size(11);
		width: 154px;
		height: 38px;
	}
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		padding-top: 8px;
	}
	span {
		display: inline-block;
		position: relative;
		padding-right: 30px;
		@include mq {
			padding-right: 24px;
		}
		&:after {
			content: '';
			position: absolute;
			background: url("../img/common/ico-next.png") no-repeat center / contain;
			width: 14px;
			height: 16px;
			top: 50%;
			transform: translateY(-50%);
			right: 0;
			@include mq {
				width: 7px;
				height: 8px;
			}
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				top: 40%;
			}
		}
	}
	&:hover {
		background-color: #ffffff;
		border-color: $color-red;
		color: $color-red;
		opacity: 1;
		text-decoration: none;
		> span:after {
			background-image: url("../img/common/ico-next-red.png");
		}
	}
	&--sm {
		width: 222px;
		height: 40px;
		@include font-size(16);
		@include mq(lg) {
			width: 176px;
			@include font-size(14);
		}
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			padding-top: 6px;
		}
		&:after {
			width: 13px;
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				top: 36%;
			}
		}
	}
	&--lg {
		border-width: 2px;
		width: 460px;
		height: 80px;
		@include font-size(23);
		@include mq {
			width: 280px;
			height: 49px;
			@include font-size(14);
		}
		span {
			padding-right: 46px;
			@include mq {
				padding-right: 28px;
			}
		}
	}
}
.btn-comp03{
	display: inline-flex;
	justify-content: center;
	padding: 14px 10px;
	text-align: center;
	min-width: 285px;
	border:solid 1px rgba(0,0,0,0.2);
	color:#000;
	@include font-size(20);
	
	&:after{
		content:"";
		display: inline-block;
		transform: translateY(6px);
		width: 13px;
		height: 15px;
		margin-left: 30px;
		background: url(../img/product/ico-right.png) no-repeat;
	}
	&:focus {
		text-decoration: none;
		color: #000;
	}
	&:hover {
		background-color: $color-text-black;
		border-color: $color-text-black;
		color: #ffffff;
		text-decoration: none;
	}
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		padding-top: 20px;
		padding-bottom: 12px;
		&:after{
			transform: translateY(2px);
		}
	}
	&--style02{
		&:after{
			display: none;
		}
	}
	@include mq{
		width: 100%;
		min-width: 100%;
		padding: 9px 10px;
		margin-bottom: 20px;
		
		@include font-size(10);
	}
}
.btn-comp04{
	text-align: center;
	@include font-size(11);
	padding-bottom: 30px;
	color:inherit;
	background: url(../img/product/ico-down.png) no-repeat bottom center;
	@include mq{
		padding-bottom: 14px;
	}
	&:hover {
		text-decoration: none;
	}
}
//fade up
.fade-up{
	opacity: 0;
	-webkit-transform: translate(0px, 120px);
	-ms-transform: translate(0px, 120px);
	transform: translate(0px, 120px);
	-webkit-transition: opacity 1s, -webkit-transform 1s;
	transition: opacity 1s, -webkit-transform 1s;
	-o-transition: transform 1s, opacity 1s;
	transition: transform 1s, opacity 1s;
	transition: transform 1s, opacity 1s, -webkit-transform 1s;
	&.active{
		-webkit-transform: translate(0px, 0px);
		-ms-transform: translate(0px, 0px);
		transform: translate(0px, 0px);
		opacity: 1;
	}
}

//
.common-block {
	position: relative;
	height: 470px;
	overflow: hidden;
	@include mq {
		height: 160px;
	}
	.inner {
		z-index: 2;
		position: absolute;
		top: 44.5%;
		transform: translateY(-50%);
		left: 0;
		right: 0;
		@include mq {
			top: 50%;
		}
	}
	img {
		height: 600px;
		width: 100%;
		object-fit: cover;
		font-family: "object-fit:cover";
		@include mq {
			height: 160px;
		}
	}
	&__ttl {
		color: #ffffff;
		span {
			@include font-size(30);
			line-height: 1.5;
			letter-spacing: 0.3em;
			display: block;
			margin-bottom: 11px;
			@include mq {
				@include font-size(14);
				letter-spacing: 0;
				line-height: 1.7;
				margin-bottom: 0;
			}
		}
		small {
			@include font-size(17);
			letter-spacing: 0.1em;
			display: block;
			@include mq {
				font-weight: bold;
				@include font-size(10);
			}
		}
	}
	&--style02 {
		@include mq(md-min) {
			.inner {
				top: 52.7%;
			}
			img {
				height: 705px;
			}
		}
	}
	&--style03 {
		@include mq(md-min) {
			.inner {
				top: 49.6%;
			}
			img {
				height: 700px;
			}
		}
	}
}

//
.block-rec {
	position: relative;
	color: #ffffff;
	border: 2px solid #ffffff;
	max-width: 452px;
	&__img img {
		width: 100%;
	}
	&__content {
		position: absolute;
		top: 47%;
		transform: translateY(-50%);
		right: 42px;
		left: 42px;
		text-align: right;
		@include mq {
			right: 16px;
			left: 16px;
		}
	}
	&__ttl {
		@include font-size(24);
		line-height: 1.45;
		@include mq {
			@include font-size(15);
		}
	}
	&__txt {
		@include font-size(14);
		@include mq {
			@include font-size(10);
		}
	}
	&--black {
		color: $color-text-black;
		.item__content {
			right: 28px;
			left: 28px;
		}
	}
}