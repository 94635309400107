.product{
	display: flex;
	flex-wrap: wrap;
	margin: 0 0 63px;
	@include mq{
		margin-bottom: 27px;
	}
	&__content{
		width: 42.8%;
		padding-top: 9px;
		@include mq{
			width: 100%;
			padding-top: 0;
		}
	}
	&__slide{
		width: 57.2%;
		padding-right: 70px;
		@include mq{
			width: 100%;
			padding: 0;
			margin-bottom: 16px;
		}
		&__for{
			margin-bottom: 60px;
			padding-left: 6px;
			img{
				@include mq{
					display: block;
					width: 100%;
					position: relative;
					z-index: 3;
				}
			}
			.slick-arrow{
				position: absolute;
				width: 26px;
				height: 26px;
				top: 50%;
				transform: translateY(-50%);
				font-size: 0;
				z-index: 2;
				&:before{
					display: none;
				}
				&.slick-prev{
					left: -21px;
					background: url("../img/product/arrow-prev.png") no-repeat;
					background-size: 26px auto;
				}
				&.slick-next{
					right: -21px;
					background: url("../img/product/arrow-next.png") no-repeat;
					background-size: 26px auto;
				}
			}
			@include mq{
				padding-left: 0;
				margin-bottom: 10px;
			}
		}
		&__nav{
			margin: 0 -11px;
			.slick-track{
				transform: translate(0)!important;
				width: 100% !important;
				display: flex;
				flex-wrap: wrap;
			}
			&__item{
				width: 24% !important;
				padding: 0 11px;
				margin-bottom: 23px;
				cursor: pointer;
				@include mq{
					margin-bottom: 13px;
				}
				@include mq(sm-ip){
					width: 24%!important;
				}
			}
		}
	}
	@include font-size(14);
	@include mq{
		@include font-size(11);
	}
	&__ttl{
		@include font-size(24);
		line-height: 1.458;
		margin-bottom: 35px;
		@include mq{
			@include font-size(18);
			margin-bottom: 17px;
		}
	}
	&__des{
		@include font-size(15);
		line-height: 1.6;
		margin-bottom: 32px;
		@include mq{
			@include font-size(11);
			margin-bottom: 15px;
		}
	}
	&__review{
		text-align: right;
		
		padding-bottom: 37px;
		border-bottom: solid 1px #cccccc;
		margin-bottom: 34px;
		@include mq{
			padding-bottom: 18px;
			margin-bottom: 19px;
		}
	}
	&__select{
		width: 35%;
		display: inline-flex;
		align-items: center;
		/*padding-left: 53px;*/
		
		@include font-size(14);
		span{
			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				padding-top: 4px;
			}
		}
		input{
			@include font-size(14);
			
			width: 80px;
			height: 40px;
			border:solid 1px #bfbfbf;
			padding-left: 44px;
			margin-left: 9px;
			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				padding-top: 7px;
			}
			&:hover, &:focus{
				outline: none;
			}
		}
		/*for IE10+*/
	
		@include mq(xl){
			padding-left: 0;
			width: auto;
			margin-right: 20px;
		}
		@include mq{
			width: 100%;
			justify-content: flex-end;
			margin-right: 5px;
			margin-bottom: 12px;
			
			@include font-size(11);
			select{
				width: 60px;
				height: 33px;
				margin-left: 7px;
				padding-left: 39px;
			}
		}
	}
	&__buy{
		margin-bottom: 20px;
		li{
			text-align: right;
			
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-bottom: 8px;
			&:first-child{
				@include mq(xl){
					justify-content: flex-start;
				}
				@include mq{
					justify-content: flex-end;
					margin-bottom: 0;
				}
			}
			@include mq{
				flex-wrap: wrap;
			}
		}
	@include mq{
		margin-bottom: 18px;
	}
	}
	&__price{
		
		font-weight: bold;
		@include font-size(23);
		
		width: 65%;
		text-align: right;
		.red{
			color:#9c0000;
		}
		span{
			@include font-size(14);
			color:#000;
			padding-right: 1em;
		}
		small{
			@include font-size(14);
		}
		@include mq(xl){
			width: auto;
		}
		@include mq{
			width: 100%;
			@include font-size(17);
			margin-bottom: 6px;
			span{
				@include font-size(11);
			}
			small{
				@include font-size(11);
			}
		}
	}
	&__point{
		text-align: right;
		margin-bottom: 11px!important;
		span{
			@include font-size(16);
			color:#a90000
		}
		@include mq{
			@include font-size(11);
			span{
				@include font-size(11);
			}
		}
	}
	&__option{
		margin-bottom: 17px;
		li{
			/*padding: 14px 20px;
			border:solid 1px #bfbfbf;			
			background: #ffffff;*/
			margin-bottom: 13px;
			@include mq{
				/*padding: 9px 20px;*/
				margin-bottom: 10px;
			}
		}
		@include mq{
			margin-bottom: 14px;
		}
		select{
			height: 49px;
			padding: 14px 20px;
			border:solid 1px #bfbfbf;
			background: #ffffff;
			margin-top: 10px;
			border-radius: 0;
			@include mq{
				height: 35px;
				padding: 9px 20px;
				margin-top: 3px;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
			}
		}
	}
	&__transition{
		text-align: right;
		@include font-size(15);
		
		margin-bottom: 10px;
		@include mq{
			@include font-size(11);
			margin-bottom: 16px;
		}
	}
	&__date{
		margin-bottom: 30px;
		text-align: right;
		@include mq{
			margin-bottom: 12px;
		}
	}
	&__sign{
		text-align: right;
		margin-bottom: 25px;
		a{
			text-decoration: underline;
			color:inherit;
			&:hover{
				text-decoration: none;
			}
		}
		@include mq{
			margin-bottom: 5px;
		}
	}
	&__info{
		text-align: right;
		li{
			margin-bottom: 4px;
			a{
				color:inherit;
				text-decoration: underline;
				&:hover{
					text-decoration: none;
				}
			}
			&:last-child{
				margin-bottom: 0;
			}
		}
		
	}
}

.btn-buy{
	width: 100%;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid rgba(255,255,255,1);
	background-color: $color-red;
	color: #ffffff;
	font-weight: bold;
	@include font-size(18);
	letter-spacing: 0.1em;
	position: relative;
	border-radius: 0;
	padding: 0;
	margin-bottom: 28px;
	@include smooth-transition;
	border: 1px solid #FFF;
	font-family: $font-mincho;
	&:hover {
		background-color: #ffffff;
		border-color: $color-red;
		color: $color-red;
		opacity: 1;
		text-decoration: none;
	}
	&:focus{
		outline: none;
	}
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		padding-top: 20px;
	}
	@include mq{
		@include font-size(15);
		padding: 7px 10px;
		margin-bottom: 24px;
	}
}
.box-sns{
	display: flex;
	align-items: center;
	justify-content: flex-end;
	transform: translateX(7px);
	margin-bottom: 22px;
	li{
		display: inline-flex;
		align-items: center;
		margin-left: 6px;
		&:last-child{
			margin-left: 0;
		}
		@include mq{
			max-width: 85px;
			overflow: hidden;
		}
	}
	.b{
		transform: scale(.5);
	}
	@include mq{
		margin-bottom: 10px;
	}
}
.resources-box{
	display: flex;
	flex-wrap: wrap;
	margin: 0 0 82px;
	padding: 41px 0 25px;
	border-top: solid 1px #cccccc;
	border-bottom: solid 1px #cccccc;
	@include mq{
		padding-top: 24px;
		padding-bottom: 19px;
		margin-bottom: 32px;
	}
	&__item{
		width: calc(50% - 30px);
		margin-right: 0;
		&:first-of-type{
			margin-left: 0;
		}
		&:last-of-type{
			color:#a40000;
			margin-left: 50px;
			margin-right: 0;
		}
		@include mq{
			width: 100%;
			margin: 0 0 10px!important;
		}
	}
	@include font-size(14);
	line-height: 2.14;
	span{
		font-weight: bold;
	}
	@include mq{
		@include font-size(11);
		line-height: 1.7;
	}
}
.footer__top{
	&--black{
		border-bottom: solid 1px #a2a1a1;
		margin-bottom: 30px;
		.footer__list{
		> li{
			color:#000;
		}
		}
	}
}
.sec-payment{
	background: url(../img/product/bg-01.jpg) repeat;
	padding: 90px 0 36px;
	@include mq{
		padding: 29px 0 10px;
		.footer__row:first-child .footer__col:first-child{
			margin-bottom: 15px;
		}
	}
	.block-rec{
		margin-bottom: 43px;
		@include mq{
			margin-bottom: 0;
		}
	}
	.footer__col{
		&:nth-child(2){
			.footer__list{
				li{
					&:last-child{
						@include mq{
							margin-bottom: 2px;
						}
					}
				}
			}
		}
	}
	.footer__list{
		margin-bottom: 0;
		padding-bottom: 0;
		
	}
	.footer__top{
		padding-bottom: 0;
		margin-bottom: 25px;
		@include mq{
			padding-bottom: 21px;
			margin-bottom: 15px;
		}
	}

	.purchase__links{
	> li{
		margin: 0;
		padding: 0 45px;
		@include mq{
			text-align: center;
			padding: 0;
			margin-bottom: 10px;
			width: 100%;
		}
	}
	}
}
.purchase__links{
	&--style02{
		justify-content: center;
	}
}
.sec-review{
	padding: 82px 0 77px;
	@include mq{
		padding: 30px 0 25px;
	}
	&--bg {
		@include mq(md-min) {
			padding: 101px 0 107px;
			&:after {
				content: '';
				position: absolute;
				z-index: -1;
				background: url("../img/index/ico-mark.png") no-repeat center / contain;
				width: 399px;
				height: 400px;
				right: -66px;
				top: -117px;
			}
		}
	}
	@include mq {
		.btn-comp04 {
			height: 28px;
			display: inline-block;
			width: 100%;
			transition: none;
			background: none;
			text-decoration: underline;
			text-underline-position: under;
			&:before {
				content: 'もっと見る';
			}
			&.on {
				&:before {
					content: '閉じる';
				}
			}
		}
	}
}

.no-review{
	margin-bottom: 35px;
	p{
		@include font-size(25);
		text-align: center;
		padding: 50px 0;
		border: solid 1px #bfbfbf;
	}
	@include mq{
		p{
			@include font-size(14);
			padding: 30px 0;
		}
	}
}
.list-review{
	margin-bottom: 33px;
	@include mq{
		margin-bottom: 18px;
	}
	&__item{
		margin-bottom: 20px;
		border: solid 1px rgba(0,0,0,0.2);
		padding: 29px 36px 25px;
		display: flex;
		flex-wrap: wrap;
		
		@include font-size(14);
		&:nth-child(n+2){
			@include mq{
				display: none;
			}
		}
		@include mq{
			padding: 13px 13px 13px;
			@include font-size(11);
		}
		.star-img{
			max-width: 83px;
			@include mq{
				margin-bottom: 10px;
			}
		}
	}
	&__info{
		@include font-size(14);
		width: 28.2%;
		letter-spacing: 0.05em;
		p{
			margin-bottom: 8px;
		}
		@include mq{
			display: none;
			@include font-size(11);
		}
	}
	&__content{
		width: 71.8%;
		.list-review__info{
			display: none;
			@include mq{
				display: block;
				width: 100%;
			}
		}
		.txt-basic{
			margin-bottom: 14px;
			@include font-size(14);
			@include mq{
				@include font-size(11);
			}
		}
		@include mq{
			width: 100%;
		}
	}
	&__ttl{
		font-weight: bold;
		@include font-size(16);
		margin-bottom: 3px;
		letter-spacing: 0.05em;
		@include mq{
			@include font-size(11);
			line-height: 1.7;
		}
	}
	.txt-basic{
		@include mq{
			margin-bottom: 11px;
		}
	}
	&__conclusion{
		text-align: right;
		font-weight: bold;
		letter-spacing: 0.05em;
		margin-right: 25px;
		@include mq{
			margin-right: 0;
		}
	}
	&.on{
		.list-review__item{
			@include mq{
				display: block;
			}
		}
	}
}
.star{
	display: flex;
	@include mq{
		margin-bottom: 10px;
	}
	li{
		width: 15px;
		height: 15px;
		background: url(../img/product/star-02.png) no-repeat center center;
		background-size: 100% auto;
		margin-right: 2px;
		&.on{
			background: url(../img/product/star-01.png) no-repeat center center;
			background-size: 100% auto;
		}
		@include mq{
			width: 14px;
			height: 14px;
			
		}
	}
}

.line-up__list {
	.item__ttl{
		a{
			color:inherit;
		}
	}
}
.sec-other{
	padding: 80px 0 65px;
	@include mq{
		padding: 30px 0 35px;
	}
	.line-up__list{
		width: 100%;
		display: block;
		margin: 0;
		.item{
			max-width: 315px;
		}
		.item__img{
			margin-bottom: 35px;
			@include mq{
				margin-bottom: 13px;
			}
		}
		.item__ttl{
			@include mq{
				margin-bottom: 2px;
			}
		}
		.slick-list{
			overflow: initial!important;
			padding: 0 8%!important;
		}
		.slick-arrow{
			position: absolute;
			width: 40px;
			height: 40px;
			top: 36%;
			transform: translateY(-50%);
			z-index: 10;
			&.slick-prev{
				left: 0;
				background: url("../img/product/arrow-prev.png") no-repeat;
				background-size: 100% auto;
			}
			&.slick-next{
				right: 0;
				background: url("../img/product/arrow-next.png") no-repeat;
				background-size: 100% auto;
			}
			@include mq{
				width: 26px;
				height: 26px;
				top: 21%;
			}
		}
	}
}
.sec-food{
	&--style02{
		.txt-basic{
			@include font-size(11);
			line-height: 1.7;
			
			padding: 0 0 30px;
			margin: 20px 20px 0;
			border-bottom: solid 1px #dcdcdc;
		}
	}
}
a{
	transition: .4s;
}