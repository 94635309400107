.sec-gift{
	position: relative;
	/*margin-top: -15.5%;*/
	margin-top: -170px;
	@include mq(xl-13){
		margin-top: 50px;
	}
	@include mq{
		margin-top: 20px;
	}
}
.gift{
	position: relative;
	color:#fff;
	img{
		display: block;
		width: 100%;
	}
	&__ttl{
		@include font-size(62.5);
		text-align: center;
		line-height: .7;
		
		padding-bottom: 23px;
		border-bottom: solid 1px #fff;
		margin-bottom: 24px;
		span{
			@include font-size(40);
		}
		small{
			display: block;
			margin-top: 30px;
			
			font-weight: 300;
			@include font-size(27.5);
		}
		@include mq(lg){
			line-height: 1;
		}
		@include mq(sm){
			@include font-size(30);
			line-height: .8;
			padding-bottom: 10px;
			margin-bottom: 7px;
			span{
				@include font-size(18);
			}
			small{
				@include font-size(11);
				margin-top: 17px;
			}
		}
	}
	p{
		text-align: center;
		@include font-size(28);
		@include mq(sm){
			@include font-size(9);
		}
	}
	&__content{
		width: 54.5%;
		position: absolute;
		z-index: 2;
		right: 0;
		top: 49%;
		transform: translateY(-50%);
		padding-right: 80px;
		@include mq(lg){
			width: 65%;
		}
		@include mq{
			width: 100%;
			padding: 0 60px;
			top: 50%;
		}
	}
}
.gift-story{
	padding-top: 100px;
	padding-bottom: 60px;
	margin-top: -60px;
	background: url(../img/feature/feather/bg-01.png) no-repeat top left -20px;
	
	.txt-basic{
		text-align: center;
		line-height: 2.1875;
		@include mq(md-min){
			@include font-size(16);
		}
		max-width: 750px;
		margin: 0 auto;
		@include mq{
			line-height: 1.81;
		}
	}
	.breadcrumb{
		margin-bottom: 36px;
	}
	@include mq{
		background: url(../img/feature/feather/bg-01-sp.png) no-repeat top 5px left 22px;
		padding-top: 27px;
		padding-bottom: 26px;
		margin-top: 0;
	}
}
.ttl-comp05{
	text-align: center;
	@include font-size(36);
	line-height: 1.44;
	
	margin-bottom: 28px;
	@include mq{
		@include font-size(18);
		margin-bottom: 14px;
	}
}
.gift-handle{
	padding-top: 50px;
	margin-bottom: 80px;
	border-top: solid 1px #c2c3c3;
	@include mq{
		overflow: hidden;
	}
	img{
		display: block;
		margin: 0 auto 30px;
	}
	.txt-basic{
		max-width: 825px;
		margin: -109px auto 0;
		padding-right: 22px;
		float: right;
		text-align: left;
		@include font-size(14);
		@include mq{
			@include font-size(11);
			margin-top: -35px;
			padding-right: 8px;
		}
	}
	@include mq{
		padding-top: 26px;
		margin-bottom: 26px;
	}
}
.img-scroll{
	/*
	height: 150px;
	background: url("../img/feature/feather/img-05.jpg") repeat-x center;
	background-size: auto 100%;
	@include mq{
		margin-bottom: 27px;
		height: 60px;
		background-image: url("../img/feature/feather/img-05-sp.jpg");
	}*/
	margin-bottom: 60px;
	@include mq{
		margin-bottom: 27px;
	}
	.item{
		width: 150px;
		@include mq{
			width: 75px;
		}
	}
}
.sec-story{
	background: url(../img/feature/feather/bg-01.png) no-repeat top 7% right -20px;
	margin-bottom: 86px;
	@include mq{
		margin-bottom: 25px;
	}
	.txt-des{
		max-width: 825px;
		margin: 0 auto 70px;
		
		@include mq(md-min){
			@include font-size(16);
		}
		line-height: 2.125;
		text-align: center;
		@include mq{
			line-height: 20px;
			margin-bottom: 30px;
		}
	}
	.ttl-comp05{
		margin-bottom: 38px;
		@include mq{
			margin-bottom: 12px;
		}
	}
	@include mq{
		background: url(../img/feature/feather/bg-01-sp.png) no-repeat top -3px right -15px;
	}
}
.list-step{

	&__ttl{
		text-align: center;
		@include font-size(30);
		margin-bottom: 17px;
		@include mq{
			@include font-size(18);
			margin-bottom: 5px;
		}
	}
	.txt-basic{
		padding-bottom: 17px;
		border-bottom: solid 1px #b0b0b0;
		margin-bottom: 30px;
		
		text-align: center;
		@include mq{
			line-height: 1.81;
			padding-bottom: 13px;
			margin-bottom: 14px;
		}
	}
	.btn-click{
		img{
			max-width: 53px;
			@include mq{
				max-width: 45px;
			}
		}
		&:hover{
					 opacity: .7;
				 }
	}
	&__img{
		img{
			@include mq(md-min){
				min-height: 350px;
				object-fit: cover;
				font-family: 'object-fit: cover';
			}
		}
		@include mq{
			order: 1;
		}
	}
	&__content{
		background: url(../img/feature/feather/bg-02.jpg) repeat;
		background-size: auto 100%;
		padding: 0 45px;
		display: flex;
		align-content: center;
		justify-content: center;
		flex-wrap: wrap;
		@include mq{
			order: 2;
			padding: 20px 20px;
		}
	}
	&__item{
		display: flex;
		flex-wrap: wrap;
		>*{
			width: 50%;
			@include mq{
				width: 100%;
			}
		}
		&:nth-child(2n){
			@include mq(md-min){
				.list-step__content{
					order:2;
				}
				.list-step__content{
					order:1;
				}
			}
		}
	}
}

.box-menu{
	margin-top: -80px;
	@include mq{
		margin-top: 0;
	}
	.img-menu{
		@include mq(md-min){
			right: -120px;
			position: relative;
		}
		@include mq(xl){
			right: -50px;
			bottom:-50px;
		}
		@include mq{
			bottom:auto;
			right: auto;
		}
	}
	.txt-right{
		img{
			max-width: 440px;
		}
		@include mq{
			text-align: center !important;
			img{
				max-width: 275px;
				margin-bottom: 24px;
				position: relative;
				z-index: 2;
			}
		}
	}
	.order__list{
		margin-top: -63px;
	}
}